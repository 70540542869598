import React, { useState, useEffect } from "react";
import { FaUser, FaSchool } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import Congratulation from "./Congratulation";
import { InitializePayment } from "../axios/axios";
import { useAuth } from "../authContext/AuthContext";

const stripePromise = loadStripe(
  "pk_test_51O0pnvHjF0yQd0p7TAgTFtWFbmJWguAxxpPwWfs43zdyNAnxICtR3OnevSP4xLMFzbmvOK0PNfBQxJ4nZpXKqo1j00ezEGUkou"
);

const ProceedPayment = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [showCongratulationPage, setShowCongratulationPage] = useState(false);

  const location = useLocation();

  const { user } = useAuth();
  const { school } = useAuth();
  const { selectedPlan, selectedPaymentOption, subscription } =
    location.state || {};
  const initiateSubscription = async () => {
    try {
      const response = await InitializePayment({
        name: subscription === "school" ? school?.name : user.name,
        email: subscription === "school" ? school?.email : user.email,
        product_name: subscription,
        subscriptionId: selectedPlan.id,
        candidateDbId: subscription === "school" ? school._id : user._id,
        recur: true,
      });
      console.log("response", response);
      setClientSecret(response.data.paymentIntent);
    } catch (error) {
      console.log(error);
      toast.error("Failed to initiate payment. Please try again.");
    }
  };
  useEffect(() => {
    initiateSubscription();
  }, []);

  return (
    <div>
      {showCongratulationPage ? (
        <Congratulation />
      ) : (
        <div
          style={{
            backgroundImage: "url(/images/subscription/subscription-bg.png)",
          }}
          className="relative min-w-[320px] min-h-[100vh] bg-fixed bg-top bg-cover bg-no-repeat"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#1D68FF] to-[#9A52DE97] opacity-75"></div>
          <div className="flex relative flex-col justify-center items-center px-4 py-8 min-h-full sm:py-16">
            <div className="flex flex-col justify-between items-end md:flex-row">
              <div className="py-5 text-white">
                <h1 className="text-5xl font-bebas max-md:text-4xl tracking-[2.55px] ">
                  Proceed to pay...
                </h1>
                <p className="mt-1 text-xs md:text-sm">
                  Complete your subscription payment securely.
                </p>
              </div>
              <img
                src="/images/subscription/student.png"
                alt="not found"
                className="w-3/4 md:w-1/3"
              />
            </div>
            <div className="flex overflow-hidden z-10 flex-col py-10 w-full max-w-5xl bg-white rounded-lg shadow-lg md:flex-row">
              <div className="w-full md:w-[37%] px-10 pt-5 my-auto">
                <div
                  className={`flex justify-between p-4 mb-4 rounded-3xl border-2`}
                >
                  <div className="flex flex-col text-[#919191]">
                    <h2 className="text-lg md:text-xl font-bold text-[#009A39]">
                      ${selectedPlan.price}.00
                    </h2>
                    <p>Yearly</p>
                    <p className="font-bold text-[#090D20] mt-2">
                      {selectedPlan.name}
                    </p>
                    <p className="text-sm">
                      This is an {selectedPlan.type} plan
                    </p>
                  </div>
                  {selectedPlan.type === "individual" ? (
                    <FaUser className="text-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
                  ) : (
                    <FaSchool className="text-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
                  )}
                </div>
              </div>
              <div className="w-full md:w-[63%] bg-white px-10 max-md:mt-10 border-l-[1px]">
                <h2 className="text-2xl text-[#009A39] font-bebas tracking-[2px] mb-7">
                  {selectedPaymentOption}
                </h2>
                {clientSecret !== null ? (
                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret: clientSecret }}
                  >
                    <ProceedPaymentForm />
                  </Elements>
                ) : (
                  <p>Loading Payment Sheet....</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ProceedPaymentForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const stripe = useStripe();
  const elements = useElements();
  const { selectedPlan, selectedPaymentOption, subscription } =
    location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      toast.error("Stripe is not ready yet.");
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + "/congratulation",
      },
    });

    if (error) {
      toast.error(error.message);
    } else {
      navigate("/congratulation", {
        state: { subscription, selectedPlan: selectedPlan.type },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <button
        type="submit"
        className="py-3 mt-5 w-full text-white bg-blue-500 rounded-xl hover:bg-blue-600"
        disabled={!stripe || !elements}
      >
        Pay ${selectedPlan.price}.00
      </button>
    </form>
  );
};

export default ProceedPayment;
