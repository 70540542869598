import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import nav from "./navconfig.json";
import { IconFinder } from "../auth/components/IconFinder";
import RatingModal from "../auth/components/allModals/RatingModal";
import { useDispatch, useSelector } from "react-redux";
import { activeTabAction } from "../redux/c_plans_slice";

const DashboardWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state) => state.careerPlanState.activeTabMemory
  );
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [planning, setPlanning] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleActiveTab = (tab) => {
    dispatch(activeTabAction(tab));
  };

  return (
    <div
      style={{
        background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
      }}
      className="flex min-h-[100vh] relative min-w-[320px]"
    >
      {showModal && <RatingModal onCancel={() => setShowModal(false)} />}
      <aside
        style={{ scrollbarWidth: "none" }}
        className="w-2/12 max-lg:hidden text-white  h-[100vh] overflow-y-scroll "
      >
        <div className="p-4 mx-auto">
          <div className="text-white">
            <img
              src="/future-capacity.png"
              alt="not found"
              className="mx-auto w-32"
            />
          </div>
        </div>
        <nav className="flex flex-col justify-between mt-4 h-full font-medium">
          <ul className="cursor-pointer md:pl-2">
            {nav.sidebar.map((singleNav, idx) => (
              <div className="relative" key={`${Math.random()}_${idx}_Sidebar`}>
                <li
                  key={idx}
                  className={`p-4 relative flex ${
                    activeTab === singleNav.activeTab
                      ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                      : "hover:bg-blue-800 rounded-[5px]"
                  }`}
                  onClick={() => {
                    singleNav.subMenu
                      ? setPlanning(!planning)
                      : handleActiveTab(singleNav.activeTab);
                  }}
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex">
                      <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                        {activeTab === singleNav.activeTab ? (
                          <IconFinder icon={singleNav.activeIcon} />
                        ) : (
                          <IconFinder icon={singleNav.icon} />
                        )}
                        {activeTab === singleNav.activeTab ? (
                          <img
                            src={singleNav.activeImage}
                            className="max-w-4"
                            alt=""
                          />
                        ) : (
                          <img
                            src={singleNav.image}
                            className="max-w-4"
                            alt=""
                          />
                        )}
                      </div>
                      {singleNav.name}
                    </div>
                    {singleNav.subMenu && (
                      <IconFinder icon={planning ? "up" : "down"} />
                    )}
                  </div>
                </li>
                {singleNav.subMenu && planning && (
                  <ul>
                    {singleNav.subMenus.map((menu, idx) => (
                      <li
                        key={`${Math.random()}_${idx}_Sidebar`}
                        className={`p-4 relative flex ml-4 ${
                          activeTab === menu.activeTab
                            ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                            : "hover:bg-blue-800 rounded-[5px]"
                        }`}
                        onClick={() => {
                          menu.subMenu
                            ? setPlanning(!planning)
                            : handleActiveTab(menu.activeTab);
                        }}
                      >
                        <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                          {activeTab === menu.activeTab ? (
                            <IconFinder icon={menu.activeIcon} />
                          ) : (
                            <IconFinder icon={menu.icon} />
                          )}
                          {menu.image && (
                            <img src={menu.image} className="max-w-4" />
                          )}
                        </div>
                        {menu.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <li className="flex p-4" onClick={() => setShowModal(true)}>
              <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                <img src="/images/dashboard/star.png" className="max-w-5" />
              </div>
              Rate Our App
            </li>
          </ul>
          <div className="">
            <img src="/student.png" className="w-[18vw] pl-2 flex " />
          </div>
        </nav>
      </aside>
      {/* Sidebar for small screen */}
      <aside
        style={{ scrollbarWidth: "none" }}
        className={`fixed top-0 left-0 h-full bg-[#1D68FF] text-white lg:hidden transform shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 ease-in-out w-52 overflow-y-scroll overflow-x-hidden z-10`}
      >
        <div className="p-4 mx-auto">
          <div className="text-white">
            <img
              src="/future-capacity.png"
              alt="not found"
              className="mx-auto w-24"
            />
          </div>
        </div>
        <nav className="flex flex-col justify-between mt-4 h-full font-medium">
          <ul className="cursor-pointer md:pl-2">
            {nav.sidebar.map((singleNav, idx) => (
              <div className="relative" key={`${Math.random()}_${idx}_Sidebar`}>
                <li
                  className={`p-4  flex ${
                    activeTab === singleNav.activeTab
                      ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                      : "hover:bg-blue-800 rounded-[5px]"
                  }`}
                  onClick={() => {
                    singleNav.subMenu
                      ? setPlanning(!planning)
                      : handleActiveTab(singleNav.activeTab);
                  }}
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex">
                      <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                        {activeTab === singleNav.activeTab ? (
                          <IconFinder icon={singleNav.activeIcon} />
                        ) : (
                          <IconFinder icon={singleNav.icon} />
                        )}
                        {activeTab === singleNav.activeTab ? (
                          <img
                            src={singleNav.activeImage}
                            className="max-w-4"
                            alt=""
                          />
                        ) : (
                          <img
                            src={singleNav.image}
                            className="max-w-4"
                            alt=""
                          />
                        )}
                      </div>
                      {singleNav.name}
                    </div>
                    {singleNav.subMenu && (
                      <IconFinder icon={planning ? "up" : "down"} />
                    )}
                  </div>
                </li>
                {singleNav.subMenu && planning && (
                  <ul>
                    {singleNav.subMenus.map((menu, idx) => (
                      <li
                        key={`${Math.random()}_${idx}_Sidebar`}
                        className={`p-4 relative flex ${
                          activeTab === menu.activeTab
                            ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                            : "hover:bg-blue-800 rounded-[5px]"
                        }`}
                        onClick={() => {
                          menu.subMenu
                            ? setPlanning(!planning)
                            : handleActiveTab(menu.activeTab);
                        }}
                      >
                        <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                          {activeTab === menu.activeTab ? (
                            <IconFinder icon={menu.activeIcon} />
                          ) : (
                            <IconFinder icon={menu.icon} />
                          )}
                          {menu.image && (
                            <img src={menu.image} className="max-w-4" alt="" />
                          )}
                        </div>
                        {menu.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <li className="flex p-4" onClick={() => setShowModal(true)}>
              <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                <img
                  src="/images/dashboard/star.png"
                  className="max-w-5"
                  alt=""
                />
              </div>
              Rate Our App
            </li>
          </ul>
          <div className="">
            <img src="/student.png" className="w-[18vw] pl-2 flex " alt="" />
          </div>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="relative lg:w-10/12  max-lg:w-[95vw] mx-auto min-w-[300px]">
        <div className="flex justify-end p-4 lg:hidden">
          <button
            className="p-2 text-white bg-blue-500 rounded-md"
            onClick={toggleSidebar}
          >
            <FaBars />
          </button>
        </div>
        <main
          style={{ scrollbarWidth: "none" }}
          className="flex-1 overflow-y-scroll overflow-x-hidden h-[95vh] bg-[#FCFCFC] my-4 max-lg:my-5 md:mr-6 rounded-xl "
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default DashboardWrapper;
