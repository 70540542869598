import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { IoMdMail } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Slick1 from "../common/Slick1";
import { userLogin } from "../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../authContext/AuthContext";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/common";
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const { login, loginSchool } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!rememberMe) {
        toast.error("Please check the 'Remember Me' checkbox.");
        setLoading(false);
        return;
      }

      await userLogin({
        email: values.email,
        password: values.password,
      })
        .then((response) => {
          if (response?.data?.data?.school) {
            let token = response.data.data.token;
            const school = response.data.data.school;
            loginSchool(school, token, 2);
            dispatch(setToken(token));
            navigate("/school-portal");
            setLoading(false);
          } else if (response?.data.user) {
            let token = response.data.token;
            const user = response.data.user;
            dispatch(setToken(token));
            login(user, token, 1);
            navigate("/");
            setLoading(false);
          }
          toast.success(response.data.message);
        })
        .catch((err) => {
          console.error("Login failed:", err);
          setLoading(false);
          toast.error("Invalid email or password. Please try again.");
        });
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    // Prevent spacebar from being the first character
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  const goToSignup = () => {
    navigate("/signUp");
  };

  const goToForgot = () => {
    navigate("/forgot-password");
  };

  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top bg-cover min-w-[320px] min-h-[100vh]"
    >
      <div className="flex py-5 lg:justify-between max-lg:px-16 max-md:px-7">
        <div className="flex flex-col lg:mx-[10%] lg:w-[30%] mt-[7%]">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[100px]"
          />
          <div className="self-center font-bebas text-4xl max-md:text-3xl mt-2.5 text-green-600 tracking-[2.55px]">
            WELCOME BACK!
          </div>
          {/* <div className="flex gap-4 self-center p-1 mt-5 mb-2 text-base text-center whitespace-nowrap bg-white rounded-2xl">
            <button
              onClick={() => setActive("Individual")}
              className={`px-6 py-2.5 rounded-2xl ${
                active === "Individual" ? "bg-[#FF9C1F] text-white" : ""
              }`}
            >
              Individual
            </button>
            <button
              onClick={() => setActive("Classroom")}
              className={`px-6 py-2.5 rounded-2xl ${
                active === "Classroom" ? "bg-[#FF9C1F] text-white" : ""
              }`}
            >
              Classroom
            </button>
          </div> */}
          <form onSubmit={formik.handleSubmit}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { marginTop: 1.5 },
                "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after":
                  {
                    borderBottom: "none !important",
                  },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px",
                },
                "& .MuiFormLabel-root": {
                  padding: "15px 3px 0px 21px",
                  fontSize: "17px",
                  color: "black",
                },
                "& .MuiInputBase-root": {
                  padding: "10px 0px 3px 15px",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="input-with-icon-textfield"
                label="Email"
                placeholder="johndoe@gmail.com"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                      <IoMdMail />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="pt-10 w-full bg-white rounded-xl"
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                  {formik.errors.email}
                </FormHelperText>
              )}
              <TextField
                variant="standard"
                label="Password"
                placeholder="***********"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className="pt-10 w-full bg-white rounded-xl"
              />
              {formik.touched.password && formik.errors.password && (
                <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                  {formik.errors.password}
                </FormHelperText>
              )}
            </Box>
            <div className="flex gap-2 justify-between mt-4 max-w-full">
              <div className="flex my-auto">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe((prev) => !prev)}
                  className="my-auto mt-0.5 mr-1.5 w-5 h-5 bg-red-50 max-sm:w-3.5 max-sm:h-3.5 max-md:mt-1"
                />
                <div className="text-base flex justify-between my-auto max-sm:text-[13px]">
                  Remember Me?
                </div>
              </div>
              <button
                onClick={() => goToForgot()}
                className="text-blue-600 cursor-pointer max-sm:text-[13px]"
              >
                Forgot Password?
              </button>
            </div>
            <Button
              style={"w-full mt-5"}
              Buttontype={"submit"}
              loading={loading}
              text={"Login"}
            />
            <div className="flex justify-center mt-7 text-base text-slate-950">
              Don’t have an account?
              <button
                onClick={() => goToSignup()}
                className="pl-1 font-medium text-blue-600 cursor-pointer"
              >
                Register
              </button>
            </div>
          </form>
        </div>
        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
