import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { getSchools, register } from "../../axios/axios";

function Individual() {
  const [rememberMe, agreeTermsCond] = useState(false);
  const [isUnder18, setIsUnder18] = useState(false);
  const [schools, setSchools] = useState([]);
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();
  const maxDate = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
  const goToLogin = () => {
    navigate("/", {});
  };

  const [showPasswords, setShowPasswords] = useState({
    password1: false,
    password2: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      schoolRef: "",
      password: "",
      confirmPass: "",
      dob: "",
      parentName: "",
      parentEmail: "",
      parentPhone: "",
      gender: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, "Invalid format")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPass: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      gender: Yup.string().required("Select Gender"),
      dob: Yup.string().required("Date of birth is required"),
      schoolRef: Yup.string().required("School is required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      if (!rememberMe) {
        toast.error("Please accept terms and conditions");
        return;
      }
      if (
        calculateAge(values.dob) < 18 &&
        (!values.parentName || !values.parentEmail || !values.parentPhone)
      ) {
        toast.error("Parent details are required");
        return;
      }
      if (
        values.name === values.parentName ||
        values.email === values.parentEmail ||
        values.phone === values.parentPhone
      ) {
        toast.error("User and Parent details cannot be the same");
        return;
      }
      try {
        await register({
          name: values.name,
          email: values.email,
          dob: values.dob,
          role: "User",
          tnc: rememberMe,
          gender: values.gender || "male",
          password: values.password,
          parentName: values.parentName,
          parentEmail: values.parentEmail,
          parentPhone: values.parentPhone,
          schoolRef: values.schoolRef,
        });
        navigate("/otp", {
          state: {
            type: "individual",
            email: values.email,
            data: { button: "Submit" },
          },
        });
      } catch (error) {
        console.error("register failed:", error);
        toast.error(error.error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (formik.values.dob) {
      const age = calculateAge(formik.values.dob);
      setIsUnder18(age < 18);
    }
  }, [formik.values.dob]);

  useEffect(() => {
    getSchools()
      .then((res) => {
        setSchools([
          { value: "", name: "Select School", _id: "" },
          ...res.data,
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleKeyDown = (e) => {
    // Prevent spacebar from being the first character
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { marginTop: 1.5 },
            "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after":
              {
                borderBottom: "none !important",
              },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
            "& .MuiFormLabel-root": {
              padding: "15px 3px 0px 21px",
              fontSize: "17px",
              color: "black",
            },
            "& .MuiInputBase-root": {
              padding: "10px 0px 3px 15px",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="name"
            label="Full Name"
            placeholder="John Doe"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaUser />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          {/* {formik.touched.name && formik.errors.name && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.name}
            </FormHelperText>
          )} */}
          <TextField
            id="email"
            label="Email"
            placeholder="johndoe@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {/* {formik.touched.email && formik.errors.email && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.email}
            </FormHelperText>
          )} */}
          <FormControl
            fullWidth
            className="w-full bg-white rounded-xl"
            sx={{ marginTop: "10px" }}
          >
            <InputLabel
              variant="standard"
              htmlFor="gender"
              shrink
              sx={{ fontSize: "14px", color: "#636363" }}
            >
              Gender
            </InputLabel>
            <NativeSelect
              id="gender"
              label="Gender"
              placeholder="Select Gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="gender"
              onKeyDown={handleKeyDown}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                color: "#636363",
                fontSize: "14px",
                margin: "0px 10px 0px 1px",
              }}
            >
              {[
                { value: "", label: "Select Gender" },
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {/* {formik.touched.gender && formik.errors.gender && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.gender}
            </FormHelperText>
          )} */}

          <TextField
            id="dob"
            type="date"
            label="Date of Birth"
            min={new Date().toISOString().split("T")[0] - 18 * 365}
            max={maxDate}
            placeholder="DD/MM/YYYY"
            value={formik.values.dob}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="dob"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {/* {formik.touched.dob && formik.errors.dob && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.dob}
            </FormHelperText>
          )} */}
          {isUnder18 && (
            <>
              <TextField
                id="parentName"
                label="Parent Full Name"
                placeholder="Jane Doe"
                value={formik.values.parentName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="parentName"
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                className="pt-10 w-full bg-white rounded-xl"
                error={
                  formik.touched.parentName && Boolean(formik.errors.parentName)
                }
              />
              {/* {formik.touched.parentName && formik.errors.parentName && (
                <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                  {formik.errors.parentName}
                </FormHelperText>
              )} */}
              <TextField
                id="parentEmail"
                label="Parent Email"
                placeholder="janedoe@gmail.com"
                value={formik.values.parentEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="parentEmail"
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                className="pt-10 w-full bg-white rounded-xl"
                error={
                  formik.touched.parentEmail &&
                  Boolean(formik.errors.parentEmail)
                }
              />
              {formik.touched.parentEmail && formik.errors.parentEmail && (
                <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                  {formik.errors.parentEmail}
                </FormHelperText>
              )}
              <TextField
                id="parentPhone"
                label="Parent Phone Number"
                placeholder="123-456-7890"
                value={formik.values.parentPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="parentPhone"
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                className="pt-10 w-full bg-white rounded-xl"
                error={
                  formik.touched.parentPhone &&
                  Boolean(formik.errors.parentPhone)
                }
              />
              {/* {formik.touched.parentPhone && formik.errors.parentPhone && (
                <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                  {formik.errors.parentPhone}
                </FormHelperText>
              )} */}
            </>
          )}
          <FormControl
            fullWidth
            className="w-full bg-white rounded-xl"
            sx={{ marginTop: "10px" }}
          >
            <InputLabel
              variant="standard"
              htmlFor="school-native-select"
              shrink
              sx={{ fontSize: "14px", color: "#636363" }}
              error={
                formik.touched.schoolRef && Boolean(formik.errors.schoolRef)
              }
            >
              School Name
            </InputLabel>
            <NativeSelect
              name="schoolRef"
              value={formik.values.schoolRef}
              onChange={formik.handleChange}
              inputProps={{
                name: "schoolRef",
                id: "school-native-select",
              }}
              sx={{
                color: "#636363",
                fontSize: "14px",
                margin: "0px 10px 0px 1px",
              }}
            >
              {schools.map((item, index) => {
                return (
                  <option key={index} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>

          <TextField
            id="password"
            variant="standard"
            label="Create New Password"
            placeholder="***********"
            type={showPasswords.password1 ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={handleKeyDown}
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password1")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password1 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
          {/* {formik.touched.password && formik.errors.password && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.password}
            </FormHelperText>
          )} */}
          <TextField
            id="confirmPass"
            variant="standard"
            label="Confirm Password"
            placeholder="***********"
            type={showPasswords.password2 ? "text" : "password"}
            value={formik.values.confirmPass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="confirmPass"
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password2")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password2 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="pt-10 w-full bg-white rounded-xl"
            error={
              formik.touched.confirmPass && Boolean(formik.errors.confirmPass)
            }
          />
          {/* {formik.touched.confirmPass && formik.errors.confirmPass && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.confirmPass}
            </FormHelperText>
          )} */}
        </Box>
        <div className="flex gap-2 justify-center mt-6 max-w-full">
          <div className="flex flex-col p-0.5">
            <input
              type="checkbox"
              className="w-5 h-5 max-md:w-4 max-md:h-4"
              checked={rememberMe}
              onChange={() => agreeTermsCond((prev) => !prev)}
            />
          </div>
          <div className="text-base leading-6">
            I agree with
            <button className="pl-1 text-blue-600 cursor-pointer">
              <Link to="/terms-conditions">terms & conditions</Link>
            </button>
          </div>
        </div>
        <button
          type="submit"
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
        >
          Send OTP
        </button>
        <div className="flex justify-center mt-7 text-base text-slate-950">
          Already have an account?{" "}
          <button
            onClick={() => goToLogin()}
            className="pl-1 font-medium text-blue-600 cursor-pointer"
          >
            Login
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Individual;
