import { Axios } from "./axios.interceptor";

export const logout = ({}) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/register`, {})
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const resendOTP = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/resend-otp`, { email })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const rateOurApp = ({ rating, comment }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/other/user/rate-our-app`, { rating, comment })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const userRateOurApp = () => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/other/user/rate-our-app`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const JourneysmapUserRegistration = () => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/journeysmap-user-registration`)
      .then((result) => {
        resolve(result?.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UploadProfilePicture = ({ file, candidateId }) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("profilePhoto", file);
    Axios.put(`/api/v1/update-profile-Abouts/${candidateId}`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const userLogin = ({ email, password, role }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/login`, {
      email,
      password,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
    // } else {
    //   Axios.post(`/api/v1/login/school`, {
    //     email,
    //     password,
    //   })
    //     .then((result) => {
    //       resolve(result);
    //     })
    //     .catch((err) => {
    //       reject(err.response.data);
    //     });
    // }
  });
};

export const FetchShowcase = () => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/get-all-showcase`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const register = (data) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const result = await Axios.post(`/api/v1/register`, data);
        resolve(result);
      } catch (error) {
        console.log("error at register", error);
        reject(error.response?.data || error.message);
      }
    })();
  });
};

export const InitializePayment = ({
  name,
  email,
  product_name,
  candidateDbId,
  recur,
  subscriptionId,
}) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/instantiate-payment`, {
      name,
      email,
      product_name,
      candidateDbId,
      recur,
      subscriptionId,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const CancelSubscriptionSchool = ({ db_id }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/cancel-school-subscription`, {
      db_id,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const CancelSubscriptionIndividual = ({ db_id }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/cancel-individual-subscription`, {
      db_id,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const GetActiveSubscriptionSchool = ({ type, db_id }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/get-subscription-detail?type=${type}&db_id=${db_id}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const AutoRenewSubscription = ({ role, db_id }) => {
  return new Promise((resolve, reject) => {
    Axios.put(`/auto-renew-subscription`, { role, db_id })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const GetCurrentSubscriptionDetails = ({
  db_id,
  type,
  subscriptionId,
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/user-active-subscription?role=${type}&userId=${db_id}&stripeSubscriptionId=${subscriptionId}`
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const GetSubscriptionList = (type, dbId) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/get-subscription-detail?type=${type}&db_id=${dbId}`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const studRegister = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/register/school`, {
      ...data,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UserSignupOtp = ({ email, otp }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify-user-signup-otp`, {
      // Axios.post(`/api/v1/verify-otp`, {
      email,
      otp,
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const SchoolSignUpOtp = ({ email, otp }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify-school-signup-otp`, {
      email,
      otp,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const forgotPass = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/forgot-password`, {
      email,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const forgotPassOtp = ({ email, otp }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify-otp`, {
      email,
      otp,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const microdentialsData = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/api/v1/get-all-microcredential/21")
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response?.data || err.message);
      });
  });
};

export const userDelete = (id) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/other/delete-user?user_db_id=${id}`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UseGetUserDetail = async (id) => {
  return await Axios.get(`/api/v1/get-profile/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const UseUpdateUserDetail = async (id, userid, data) => {
  const formdata = new FormData();
  if (data?.tagline) {
    formdata.append("tagline", data?.tagline);
  }
  if (data?.profilePhoto) {
    formdata.append("profilePhoto", data?.profilePhoto);
  }
  if (data?.about) {
    formdata.append("about", data?.about);
  }
  if (data?.interests.length > 0) {
    data.interests.map((value, idx) =>
      formdata.append(`interests[${idx}]`, value)
    );
  }
  if (data?.documents) {
    formdata.append("documents", data?.documents);
  }
  if (data?.showcase) {
    formdata.append("showcase", data?.showcase);
  }
  if (data?.endorsements) {
    formdata.append("endorsements", data?.endorsements);
  }
  if (data?.batch) {
    formdata.append("batch", data?.batch);
  }
  if (data?.industries) {
    data.industries.map((value) => formdata.append(`industries`, value));
  }
  if (data?.achievementImages) {
    data.achievementImages.map((value) =>
      formdata.append(`achievementImages`, value)
    );
  }
  if (data?.showcase) {
    data.showcase.map((value, idx) =>
      formdata.append(`showcase[${idx}]`, value)
    );
  }
  if (data?.deleteDocumentsName) {
    data.deleteDocumentsName.map((value, idx) =>
      formdata.append(`deleteDocumentsName[${idx}]`, value)
    );
  }
  if (data?.achievements) {
    data.deleteDocumentsName.map((value, idx) =>
      formdata.append(`deleteDocumentsName[${idx}]`, value)
    );
  }
  return await Axios.get(
    `/api/v1/update-profile/${id}/65bcb703b736c4db89264794`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const UseGetMentors = async () => {
  return await Axios.get(`/api/v1/get-all-mentors`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const UseGetSingleMentor = async (id) => {
  return await Axios.get(`/api/v1/get-all-mentors`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const UseSendInvitation = async () => {
  return await Axios.get(`/api/v1/get-all-mentors`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const UseGetPost = async (page, limit, searchQuery, district) => {
  return await Axios.get(
    `/api/v1/get-all-posts/anytime?page=${page}&limit=${limit}&search=${searchQuery}&district=${district}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const GetMyPosts = async (page, limit, searchQuery) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/my-post?page=${page}&limit=${limit}&search=${searchQuery}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
export const UseGetComments = async (postId) => {
  return await Axios.get(`/api/v1/get-post-comments/${postId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const AddComment = async (postId, data) => {
  return await Axios.post(`/api/v1/add-comment/${postId}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const DeletePost = async (postId) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/delete-post/${postId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
export const UseGetMyPost = async (candidateId, page, limit) => {
  return await Axios.get(
    `/api/v1/my-post/anytime?candidate_db_id=${candidateId}&page=${page}&limit=${limit}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const createPost = (uId, data) => {
  const formdata = new FormData();
  formdata.append("userId", uId);
  formdata.append("content", data.text);
  if (data?.images) {
    data.images.map((value) => formdata.append("media", value));
  }
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/create-post/${uId}`, formdata)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const updatePost = (pId, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/update-post/${pId}`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UseGetMicroCreadiential = async (candidateId, searchQuery) => {
  return await Axios.get(
    `/api/v1/get-all-microcredential/${candidateId}?search=${searchQuery}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const GetIndustryBasedOnSuperpower = async () => {
  return await Axios.get(`/api/v1/get-industry-by-superpower`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const GetPerformanceGuide = async () => {
  return await Axios.get(`/api/v1/get-performance-guide`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const GetAssessment = async () => {
  return await Axios.get(`/api/v1/get-assessment-results`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const GetBDNA5Score = async () => {
  return await Axios.get(`/api/v1/get-bdna5-scores`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const GetUniqueStyle = async () => {
  return await Axios.get(`/api/v1/get-unique-style`).then((res) => {
    return res.data;
  });
};

export const GetHiringInsights = async () => {
  return await Axios.get(`/api/v1/get-hiring-insights`).then((res) => {
    return res.data;
  });
};

export const downloadProfileAsPdf = async (candidateId, data) => {
  return await Axios.post(`/download-profile-as-pdf/${candidateId}`, data).then(
    (res) => {
      return res;
    }
  );
};

export const UpdateAboutUs = async (candidateId, data) => {
  return await Axios.put(`/api/v1/update-profile-Abouts/${candidateId}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
export const StoreDocuments = async (candidateId, data) => {
  const formData = new FormData();
  formData.append("documents", data);
  return await Axios.put(
    `/api/v1/update-profile-Abouts/${candidateId}`,
    formData
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getDistricts = async () => {
  return await Axios.get("/api/v1/districts").then((res) => {
    return res.data;
  });
};

export const getSchools = async () => {
  return await Axios.get("/schools")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
