import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { imageLink } from "../../../auth/components/commonFunction";

function AddEditAchievements({
  setAddAchiements,
  onSave,
  achievement,
  onDelete,
  onEdit,
  onBack,
}) {
  const [inputText, setInputText] = useState("");
  const [textareaText, setTextareaText] = useState("");
  const fileInputRef = useRef(null);
  const [file, setFile] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const getWordCount = (text) => {
    if (text.trim() === "") return 0;
    return text.trim().split(/\s+/).length;
  };

  useEffect(() => {
    if (achievement) {
      setInputText(achievement.title || "");
      setTextareaText(achievement.description || "");
      setFile(achievement.achievementImages || "");
    }
  }, [achievement]);
  const handleSave = () => {
    onSave({
      title: inputText,
      description: textareaText,
      achievementImages: file,
    });
    setAddAchiements(null); // reset alreadt existed data
    setAddAchiements(false); // Optionally close the modal
  };

  return (
    <div className="flex flex-col w-full p-5 mt-6 bg-white shadow-2xl rounded-xl max-md:px-5 max-md:max-w-full">
      <div className="flex items-start self-start justify-between max-w-full gap-4">
        <div
          onClick={() => onBack()}
          style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
          className="p-3 bg-white rounded-full cursor-pointer max-md:p-2"
        >
          <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
        </div>
        <div className="self-start mt-2 text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
          {achievement ? "EDIT Achievements" : "Add Achievements"}
        </div>
      </div>
      <div className="self-start ml-[62px] text-sm leading-loose text-neutral-400 max-md:ml-2.5">
        {achievement
          ? "In this section, edit your achievements"
          : "In this section, add your multiple achievements"}
      </div>
      <div className="px-24 max-md:px-0">
        <div
          onClick={handleUploadClick}
          className="flex flex-col items-center self-center justify-center w-full max-w-full px-10 leading-loose text-center border border-dashed py-7 mt-9 rounded-xl bg-zinc-100 bg-opacity-80 border-stone-300 max-md:px-5"
        >
          {achievement && achievement.achievementImages ? (
            <>
              <div className="flex flex-col items-center w-full max-w-full">
                <img
                  src={imageLink(achievement.achievementImages)}
                  alt="Achievement"
                  className="object-contain aspect-square w-[30px]"
                />
                <div className="text-xs text-neutral-400">
                  Supports JPEG, PNG
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
                accept="image/jpeg, image/png"
              />
              {file && (
                <span className="font-medium text-[14px]">[{file.name}]</span>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-col items-center w-full max-w-full">
                <div className="self-stretch mt-2 text-xs bg-clip-text bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] text-indigo-950">
                  <span className="text-blue-600">Browse </span>
                  <span className="text-indigo-950">your image</span>
                </div>
                <div className="text-xs text-neutral-400">
                  Supports JPEG, PNG
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
                accept="image/jpeg, image/png"
              />
              {file && (
                <span className="font-medium text-[14px]">[{file.name}]</span>
              )}
            </>
          )}
        </div>
        <div className="w-full mx-auto">
          <div className="mt-10 max-md:mt-5">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="p-3 rounded-lg"
            >
              <div className="text-base font-semibold">Title</div>
              <input
                type="text"
                className="w-full text-base border-none rounded-md outline-none "
                placeholder="In this section, add your title"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
            </div>
            <div className="mt-1 text-sm text-right text-gray-500">
              {getWordCount(inputText)}/12
            </div>
          </div>
          <div className="mt-7 max-md:mt-5">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="p-3 rounded-lg"
            >
              <div className="text-base font-semibold">Description</div>
              <textarea
                className="w-full border-none outline-none"
                placeholder=""
                value={textareaText}
                onChange={(e) => setTextareaText(e.target.value)}
                rows={4}
              />
            </div>
            <div className="mt-1 text-sm text-right text-gray-500">
              {getWordCount(textareaText)}/100
            </div>
          </div>
        </div>
        <div className="flex self-center w-full max-w-full gap-6 text-base text-center text-white mt-9 whitespace-nowrap max-md:gap-3">
          {achievement ? (
            <button
              onClick={() => setIsModalOpen(true)}
              style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
              className="flex items-center justify-center w-12 h-12 p-3 bg-white cursor-pointer rounded-xl max-md:p-2"
            >
              <RiDeleteBin6Line className="text-[#FF1942] text-xl max-md:w-4 max-md:h-4" />
            </button>
          ) : (
            ""
          )}

          <button
            onClick={() => {
              if (achievement && achievement?._id != undefined) {
                onEdit({
                  title: inputText,
                  description: textareaText,
                  achievementImages: file || achievement.achievementImages,
                  _id: achievement._id,
                });
              } else {
                handleSave();
              }
            }}
            className="p-4 max-w-full w-full text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
          >
            Save
          </button>
        </div>
      </div>
      {isModalOpen ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none max-md:mx-3">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-2xl focus:outline-none">
                {/*header*/}
                <div className="flex flex-col rounded-none ">
                  <div className="flex flex-col w-full pb-16 bg-white pt-7 rounded-3xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-end mr-6 h-8 w-8 bg-white rounded-2xl shadow-[0px_20px_50px_rgba(0,0,0,0.12)] max-md:mr-2.5">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="text-blue-600 "
                      >
                        &#10005;
                      </button>
                    </div>
                    <div className="flex flex-col w-full px-10 mt-1 text-center max-md:mt-10 max-md:max-w-full">
                      <div className="text-2xl uppercase text-[#FF5271] font-bebas tracking-[0.5px] max-md:max-w-full max-md:text-xl">
                        delete ACHIEVEMENT
                      </div>
                      <div className="text-base leading-relaxed text-neutral-400 max-md:max-w-full">
                        Are you sure you want to cancel your achievement?
                      </div>
                      <button
                        onClick={() => onDelete(achievement._id)}
                        className="md:w-[80%] mx-auto max-md:w-full px-3 py-4 mt-10 text-sm text-white whitespace-nowrap bg-[#FF1942] rounded-2xl "
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-20"></div>
        </>
      ) : null}
    </div>
  );
}

export default AddEditAchievements;
