import React, { useState, useEffect } from "react";
import OTPInput from "react-otp-input";
import Slick1 from "./Slick1";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UserSignupOtp,
  SchoolSignUpOtp,
  forgotPassOtp,
  resendOTP,
} from "../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/common";
import { useAuth } from "../authContext/AuthContext";
function Otp() {
  const [timeLeft, setTimeLeft] = useState(600); //10 min
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { type, data, email } = location.state || {};
  const buttonText = data?.button || "Submit";
  const { login } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: email,
      otp: "",
    },

    onSubmit: async (values) => {
      try {
        if (type === "individual") {
          const response = await UserSignupOtp({
            email: values.email,
            otp: values.otp,
          });
          if (response) {
            const { token, user } = response.data;
            dispatch(setToken(token));
            login(user, token, 1);
            toast.success("OTP verified successfully!");
            navigate("/subscriptions");
          }
        } else if (type === "school") {
          const response = await SchoolSignUpOtp({
            email: values.email,
            otp: values.otp,
          });
          if (response) {
            toast.success("OTP verified successfully!");
            navigate("/school-portal");
          }
        } else if (type === "forgotPass") {
          const response = await forgotPassOtp({
            email: values.email,
            otp: values.otp,
          });
          if (response) {
            toast.success("Password reset OTP verified!");
            navigate("/create-password");
          }
        }
      } catch (err) {
        console.error("Error during OTP verification:", err);
        toast.error("OTP verification failed. Please try again.");
      }
    },

    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleResendCode = async () => {
    try {
      const response = await resendOTP({ email: formik.values.email });
      if (response) {
        toast.success("OTP sent successfully!");
        setTimeLeft(600);
        setIsResendDisabled(true);
      }
    } catch (err) {
      console.error(err.message, "Error during OTP resend:", err);
      toast.error("Failed to resend OTP. Please try again.");
    }
  };

  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top bg-cover min-w-[320px] min-h-[100vh]"
    >
      <ToastContainer />
      <div className="py-5 w-full max-lg:px-16 max-md:px-7">
        <div className="flex flex-col justify-center lg:ml-[10%] mt-[5%] w-[32%] max-lg:w-full">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[100px]"
            alt=""
          />
          <div className="self-center font-bebas tracking-[2.55px] mt-2.5 text-4xl max-md:text-3xl text-green-600 ">
            VERIFICATION CODE
          </div>
          <div className="mt-1.5 text-base text-center text-zinc-500 max-md:max-w-full">
            <span className="leading-5 text-zinc-500">
              Please, check your email. We’ve sent you
            </span>
            <br />
            <span className="leading-5 text-zinc-500">the code at</span>
            <span className="leading-5 text-slate-950">
              {" "}
              {formik.values.email}
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-3 justify-center mt-5 text-dark">
              <OTPInput
                name="otp"
                value={formik.values.otp}
                onChange={(otp) => formik.setFieldValue("otp", otp)}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                renderSeparator={<span></span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="focus:border-[#1D68FF] border-2 "
                    style={{
                      height: "65px",
                      width: "65px",
                      textAlign: "center",
                      borderRadius: "10px",
                      margin: "0 5px",
                      outline: "none",
                      paddingBottom: "6px",
                      fontSize: "30px",
                    }}
                  />
                )}
              />
            </div>
            <div className="mt-5 text-center text-zinc-500">
              Time left:{" "}
              {timeLeft > 0
                ? `${Math.floor(timeLeft / 60)}:${
                    timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60
                  }`
                : "Time's up!"}
            </div>
            <button
              type="submit"
              style={{
                background:
                  "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
              }}
              className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl"
            >
              {buttonText}
            </button>
          </form>
          <div className="flex justify-center mt-5 text-base text-slate-950">
            Didn’t receive a code?
            <span
              className={`pl-1 font-medium text-blue-600 cursor-pointer ${
                isResendDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => handleResendCode()}
            >
              Resend Code
            </span>
          </div>
        </div>
        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
    </div>
  );
}

export default Otp;
