import React, { useEffect, useRef, useState } from "react";
import HeaderDash from "./components/HeaderDash";
import CommentSection from "./components/CommentSection";
import { useDispatch } from "react-redux";
import { activeTabAction } from "../redux/c_plans_slice";
import ExerciseModal from "../auth/components/allModals/ExerciseModal";
import { useSelector } from "react-redux";
import { ShowExamModelAction } from "../redux/global_values";
import { CallingAxios, imageLink } from "../auth/components/commonFunction";
import {
  GetIndustryBasedOnSuperpower,
  UseGetMicroCreadiential,
} from "../axios/axios";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa6";
const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.common.userMemory);
  const [modalVisible, setModalVisible] = useState(false);
  const [allMicro, setAllMicro] = useState([]);
  const [allIndustryBasedOnSuperpower, setAllIndustryBasedOnSuperpower] =
    useState([]);
  const showExamModel = useSelector(
    (state) => state.globalValues.ShowExamModel
  );
  const industryRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (showExamModel.enable === true) {
      setModalVisible(true);
    }
  }, [showExamModel]);

  useEffect(() => {
    CallGetMicrovredentials();
    CallGetIndustryBasedOnSuperpower();
  }, []);

  const CallGetMicrovredentials = async () => {
    await CallingAxios(
      UseGetMicroCreadiential(user.candidateId, "")
        .then((response) => {
          setAllMicro(response.data);
        })
        .catch((err) => {
          toast.error("some tecnical error");
        })
    );
  };
  const CallGetIndustryBasedOnSuperpower = async () => {
    await CallingAxios(GetIndustryBasedOnSuperpower(user.candidateId))
      .then((response) => {
        setAllIndustryBasedOnSuperpower(response.data);
        industryRef.current.scrollLeft = 0;
      })
      .catch((err) => {
        toast.error("some tecnical error");
      });
  };
  return (
    <main className="p-4 min-w-[320px]">
      <HeaderDash />
      {modalVisible && !user.dna_assignment && (
        <ExerciseModal
          onCancel={() => {
            setModalVisible(!modalVisible);
            dispatch(ShowExamModelAction({ enable: false }));
          }}
        />
      )}
      <div className="mt-8 max-md:mr-2.5 max-lg:max-w-full">
        <div className="flex relative gap-5 max-lg:flex-col">
          {user.uniqueStyle && (
            <p className="absolute top-0 right-0 flex-auto self-center text-lg text-blue-600 max-md:max-w-full">
              Your Unique Style is{" "}
              <span className="font-bold">{user.uniqueStyle}</span>
            </p>
          )}
          <div className="flex flex-col w-[67%] max-md:ml-0 max-lg:w-full">
            <div className="flex flex-col mt-1.5 w-full max-md:mt-8 max-md:max-w-full">
              <div className="flex flex-col flex-wrap items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600">
                <div className="flex">
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-right.png"
                    className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
                    alt=""
                  />
                  <div className="flex-auto self-start max-md:max-w-full">
                    Dream big, strive for greatness!
                  </div>
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-left.png"
                    className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col pt-5 pb-4 pl-8 mt-5 w-full bg-white rounded-xl shadow-2xl h-fit max-md:pl-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-5 justify-between mr-6 max-md:mr-2.5 max-md:max-w-full">
                  <div className="text-[21px] font-bebas tracking-[1px] text-slate-950">
                    Industries Based On Superpowers
                  </div>
                  <div className="flex gap-2.5 self-start">
                    <img
                      loading="lazy"
                      src="/images/Left_Right.svg"
                      className="object-contain rounded-full p-1  bg-stone-400 shrink-0 aspect-square w-8 hover:cursor-pointer hover:bg-[#1D68FF]"
                      alt="right arrow"
                      onClick={() => {
                        industryRef.current.scrollLeft -= 100;
                      }}
                    />
                    <img
                      loading="lazy"
                      src="/images/Left_Arrow.svg"
                      className="object-contain rounded-full p-1  bg-stone-400 shrink-0 aspect-square w-8 hover:cursor-pointer hover:bg-[#1D68FF]"
                      alt="left arrow"
                      onClick={() => {
                        industryRef.current.scrollLeft += 100;
                      }}
                    />
                  </div>
                </div>
                {!user.dna_assignment && (
                  <div className="gap-5 m-auto mt-4">
                    <img
                      loading="lazy"
                      src="/images/dashboard/search.png"
                      className="object-contain shrink-0 aspect-square"
                      alt=""
                    />
                    <button
                      onClick={() => setModalVisible(!modalVisible)}
                      style={{
                        background:
                          "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                      }}
                      className="px-4 py-3.5 mt-5 text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
                    >
                      Complete The Exercise
                    </button>
                  </div>
                )}
                <div
                  className="flex overflow-x-auto gap-4 pr-4 pb-4 mt-4 thin-scrollbar min-w-[100%]"
                  ref={industryRef}
                >
                  {allIndustryBasedOnSuperpower.length > 0 &&
                    user.dna_assignment &&
                    allIndustryBasedOnSuperpower.map((industry, ind) => (
                      <div
                        key={`${industry.id}-${ind}`}
                        onClick={() => {
                          window.open(industry.link, "_blank");
                        }}
                        className="gap-2 p-2 rounded-xl border-2  min-h-[250px] min-w-[300px]"
                      >
                        <img
                          src={
                            imageLink(industry.names?.[0].image) ||
                            "/images/dashboard/microcredential1.png"
                          }
                          className="my-auto h-[150px] w-[300px]"
                          draggable={false}
                        />
                        <div className="flex flex-col">
                          <p className="text-lg font-medium text-black">
                            {industry.names?.[0].name}
                          </p>
                          <p className="text-sm text-neutral-400">
                            {industry.names?.[0].description}
                          </p>
                        </div>
                      </div>
                    ))}
                  {allIndustryBasedOnSuperpower.length === 0 && (
                    <div className="flex justify-center items-center w-full h-full">
                      <p className="p-4 text-xl">
                        No Industry Based On Superpowers Found
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col px-5 py-7 mt-5 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-5 justify-between max-w-full">
                  <div className="text-[21px] font-bebas tracking-[1px] text-slate-950">
                    MICROCREDENTIALS
                  </div>
                  <button
                    onClick={() => dispatch(activeTabAction(4))}
                    className="my-auto text-sm text-stone-500"
                  >
                    View all
                  </button>
                </div>
                <div className="mt-4">
                  {isLoading ? (
                    <div className="flex justify-center items-center h-full">
                      <div className="w-12 h-12 rounded-full border-t-2 border-b-2 border-blue-500 animate-spin"></div>
                    </div>
                  ) : (
                    allMicro.slice(0, 3).map((item, ind) => {
                      return (
                        <div
                          className="flex flex-wrap gap-5 mt-3 cursor-pointer border-[1px] pl-1 pr-2 rounded-lg"
                          key={ind}
                          onClick={() => {
                            window.open(item.courseLink, "_blank");
                          }}
                        >
                          <div className="flex gap-y-5 gap-x-16 justify-between items-center w-full bg-white max-md:flex-wrap max-md:max-w-full">
                            <div className="flex gap-4 text-black">
                              <img
                                loading="lazy"
                                src={
                                  item?.img
                                    ? imageLink(item?.img)
                                    : "/images/dashboard/microcredential1.png"
                                }
                                className=" h-[55px] md:my-auto max-md:mt-1 w-[55px]"
                                alt=""
                              />
                              <div className="flex flex-col max-md:max-w-full">
                                <div className="self-start text-base font-medium">
                                  {item.courseName}
                                </div>
                                <div className="text-sm max-md:max-w-full">
                                  {item.description}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3 my-auto text-sm whitespace-nowrap text-neutral-700">
                              <div className="text-lg text-blue-500">
                                {" "}
                                <FaUser />
                              </div>
                              <div>{item.totalNumberOfLearners}</div>
                            </div>
                            <FaGreaterThan />
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[33%] my-auto max-md:ml-0  max-lg:w-full ">
            <CommentSection />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
