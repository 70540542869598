import React, { useState, useEffect, useRef } from "react";
import { GoHome, GoHomeFill } from "react-icons/go";
import { FaBars } from "react-icons/fa"; // Import the icon for the toggle button
import { useSelector, useDispatch } from "react-redux";
import nav from "./navconfig.json";
import { schoolActiveTabAction } from "../redux/c_plans_slice";
import { IconFinder } from "../auth/components/IconFinder";

const StudentDashWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const schoolActiveTab = useSelector(
    (state) => state.careerPlanState.schoolActiveTabMemory
  );
  const cPlan = useSelector((state) => state.careerPlanState.c_plan_answered);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [feedback, setFeedback] = useState("");

  const [planning, setPlanning] = useState(false);
  const planningRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Handle clicks outside the planning section
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (planningRef.current && !planningRef.current.contains(event.target)) {
        setPlanning(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  var count = 5;
  const handleClick = (index) => {
    setRating(index + 1);
  };

  const handleMouseOver = (index) => {
    setHoverValue(index + 1);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const handleActiveTab = (tab) => {
    dispatch(schoolActiveTabAction(tab));
  };
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
      }}
      className="flex min-h-[100vh] relative min-w-[320px]"
    >
      {/* Sidebar for full screen */}
      <aside
        style={{ scrollbarWidth: "none" }}
        className="w-2/12 max-lg:hidden text-white  h-[100vh] overflow-y-scroll "
      >
        <div className="p-4 mx-auto">
          <div className="text-white">
            <img
              src="/future-capacity.png"
              alt="not found"
              className="mx-auto w-32"
            />
          </div>
        </div>
        <nav className="flex flex-col justify-between mt-4 h-full font-medium">
          <ul className="cursor-pointer md:pl-2">
            {nav.sidebarSchool.map((singleNav, idx) => (
              <li
                key={idx}
                className={`p-4 relative flex ${
                  schoolActiveTab === singleNav.activeTab
                    ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                    : "hover:bg-blue-800 rounded-[5px]"
                }`}
                onClick={() => {
                  handleActiveTab(singleNav.activeTab);
                }}
              >
                <div className="flex justify-between items-center w-full">
                  <div className="flex">
                    <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                      {schoolActiveTab === singleNav.activeTab ? (
                        <IconFinder icon={singleNav.activeIcon} />
                      ) : (
                        <IconFinder icon={singleNav.icon} />
                      )}
                      {schoolActiveTab === singleNav.activeTab ? (
                        <img src={singleNav.activeImage} className="max-w-4" />
                      ) : (
                        <img src={singleNav.image} className="max-w-4" />
                      )}
                    </div>
                    {singleNav.name}
                  </div>
                  {singleNav.subMenu && (
                    <IconFinder icon={planning ? "up" : "down"} />
                  )}
                </div>
              </li>
            ))}
            <li
              className={`flex relative p-4 ${"hover:bg-blue-800 rounded-[5px]"}`}
              onClick={() => {
                window.open("https://learn.autonome101.com/log-in/", "_blank");
              }}
            >
              <div className="flex justify-between items-center w-full">
                <div className="flex">
                  <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                    <IconFinder icon="book" />
                  </div>
                  Autonome
                </div>
              </div>
            </li>
            {/* <li
              className={`p-4  flex ${activeTab === 1
                ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                : "hover:bg-blue-800 rounded-[5px]"
                }`}
              onClick={() => setActiveTab(1)}
            >
              <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                {activeTab === 1 ? <GoHomeFill /> : <GoHome />}
              </div>
              Dashboard
            </li>
            <li
              className={`p-4 flex ${activeTab === 2
                ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                : "hover:bg-blue-800 rounded-[5px]"
                }`}
              onClick={() => setActiveTab(2)}
            >
              <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                {activeTab === 2 ? <GoHomeFill /> : <GoHome />}
              </div>
              Subscriptions
            </li>*/}
            <li className="flex p-4" onClick={() => setShowModal(true)}>
              <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                <img src="/images/dashboard/star.png" className="max-w-5" />
              </div>
              Rate Our App
            </li>
          </ul>
          <div className="">
            <img src="/student.png" className="w-[18vw] pl-2 flex " />
          </div>
        </nav>
      </aside>
      {/* Sidebar for small screen */}
      <aside
        style={{ scrollbarWidth: "none" }}
        className={`fixed top-0 left-0 h-full bg-[#1D68FF] text-white lg:hidden transform shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 ease-in-out w-52 overflow-y-scroll overflow-x-hidden z-50`}
      >
        <div className="p-4 mx-auto">
          <div className="text-white">
            <img
              src="/future-capacity.png"
              alt="not found"
              className="mx-auto w-24"
            />
          </div>
        </div>
        <nav className="flex flex-col justify-between mt-4 h-full font-medium">
          <ul className="cursor-pointer md:pl-2">
            {nav.sidebar.map((singleNav, idx) => (
              <li
                key={idx}
                className={`p-4  flex ${
                  schoolActiveTab === singleNav.activeTab
                    ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                    : "hover:bg-blue-800 rounded-[5px]"
                }`}
                onClick={() => {
                  handleActiveTab(singleNav.activeTab);
                }}
              >
                <div className="flex justify-between items-center w-full">
                  <div className="flex">
                    <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                      {schoolActiveTab === singleNav.activeTab ? (
                        <IconFinder icon={singleNav.activeIcon} />
                      ) : (
                        <IconFinder icon={singleNav.icon} />
                      )}
                      {schoolActiveTab === singleNav.activeTab ? (
                        <img src={singleNav.activeImage} className="max-w-4" />
                      ) : (
                        <img src={singleNav.image} className="max-w-4" />
                      )}
                    </div>
                    {singleNav.name}
                  </div>
                  {singleNav.subMenu && (
                    <IconFinder icon={planning ? "up" : "down"} />
                  )}
                </div>
              </li>
            ))}
            <li className="flex p-4" onClick={() => setShowModal(true)}>
              <div className="my-auto text-xl md:mr-2 max-md:mr-1">
                <img src="/images/dashboard/star.png" className="max-w-5" />
              </div>
              Rate Our App
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="relative lg:w-10/12  max-lg:w-[95vw] mx-auto min-w-[300px] ">
        <div className="flex justify-end p-4 lg:hidden">
          <button
            className="p-2 text-white bg-blue-500 rounded-md"
            onClick={toggleSidebar}
          >
            <FaBars />
          </button>
        </div>
        <main
          style={{ scrollbarWidth: "none" }}
          className="flex-1 overflow-y-scroll overflow-x-hidden h-[88vh] bg-[#FCFCFC] my-10 max-lg:my-5 md:mr-6 rounded-xl "
        >
          {children}
        </main>

        {/* Footer */}
        {/* <footer className="flex justify-between px-44 w-full">
          <p className="items-end text-sm text-white rounded-lg">
            Copyright © My Future Capacity
          </p>
          <div className="flex gap-5">
          <p className="items-end text-sm text-white rounded-lg">
            Privacy Policy
          </p>
          <p className="items-end text-sm text-white rounded-lg">
            Terms & Condition
          </p>
          </div>
        </footer> */}
      </div>
      {showModal ? (
        <>
          <div className="flex overflow-auto fixed inset-0 z-50 justify-center items-center outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-3xl shadow-lg mx-5 relative flex flex-col bg-white outline-none focus:outline-none max-w-[500px]">
                <div className="flex overflow-hidden flex-col rounded-3xl">
                  <div className="flex relative flex-col items-center w-full max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/images/dashboard/rate.png"
                      className=""
                    />
                    <h1
                      onClick={() => setShowModal(false)}
                      className="flex absolute top-3 right-3 justify-center items-center my-auto w-9 h-9 text-lg font-semibold text-blue-600 bg-white rounded-xl cursor-pointer"
                    >
                      &#10005;
                    </h1>

                    <div className="mt-3 text-2xl tracking-wider leading-snug font-bebas max-md:text-center text-slate-950 max-md:max-w-full max-md:text-xl">
                      How Would you rate our App EXPERIENCE?
                    </div>
                    <div>
                      {[...Array(count)].map((_, index) => (
                        <span
                          key={index}
                          style={{
                            cursor: "pointer",
                            color:
                              (hoverValue || rating) > index ? "gold" : "gray",
                          }}
                          onClick={() => handleClick(index)}
                          onMouseOver={() => handleMouseOver(index)}
                          onMouseLeave={handleMouseLeave}
                          className="text-[60px] max-md:text-[40px]"
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                    <input
                      type="text"
                      className="self-stretch border-none outline-none p-3 mx-7 max-md:mx-2 mt-6 max-md:mt-4 text-base max-md:text-[13px] rounded-xl bg-zinc-300 bg-opacity-40 text-zinc-500 max-md:max-w-full"
                      placeholder=" Say something more about this app"
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />
                    <button
                      onClick={() => setShowModal(false)}
                      className="py-4 mt-7 w-[60%] mb-4 max-w-full text-base text-center text-white whitespace-nowrap rounded-xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-30"></div>
        </>
      ) : null}
    </div>
  );
};

export default StudentDashWrapper;
