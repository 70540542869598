import React from "react";

const CustomDNAGraph = ({ value, description, label }) => {
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / 100) * circumference;

  const getLabel = (label) => {
    const a = label.split("Vs");
    const b = a[0].charAt(0).toUpperCase() + a[0].slice(1);
    const c = a[1].charAt(0).toUpperCase() + a[1].slice(1);
    return (
      <p className="flex items-center gap-1">
        <span className="text-[#01B1E6]">{b}</span>
        <span className="font-bold text-black">vs</span>
        <span className="text-[#22c55e]">{c}</span>
      </p>
    );
    console.log(a);

    return "a";
  };

  return (
    <div role="status" className="flex">
      <div className="relative w-16 h-16 mr-4">
        <svg
          className="w-full h-full"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background Circle (Blue) */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#01B1E6" /* Blue color */
            strokeWidth="10"
            fill="none"
          />

          {/* Progress Arc (Green) */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#22c55e" /* Green color */
            strokeWidth="10"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            transform="rotate(-90 50 50)"
          />
        </svg>
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <span className="text-lg font-semibold">{value}%</span>
        </div>
      </div>
      <div>
        <p className="text-sm font-semibold">{getLabel(label)}</p>
        <p className="text-sm text-neutral-400">{description}</p>
      </div>
    </div>
  );
};

export default CustomDNAGraph;

{
  /**
    
    import React from "react";

const CustomDNAGraph = ({ value, description, label }) => {
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / 100) * circumference;

  return (
    <div role="status" className="flex items-center space-x-4">
      <div className="relative w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20">
        <svg
          className="w-full h-full"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#3b82f6"
            strokeWidth="10"
            fill="none"
          />

          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#22c55e" 
            strokeWidth="10"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            transform="rotate(-90 50 50)"
          />
        </svg>
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <span className="text-xs font-semibold sm:text-sm md:text-lg">
            {value}%
          </span>
        </div>
      </div>
      <div>
        <p className="text-xs font-semibold sm:text-sm">{label}</p>
        <p className="text-xs sm:text-sm text-neutral-400">{description}</p>
      </div>
    </div>
  );
};

export default CustomDNAGraph;

*/
}
