import React from "react";
import { ClearPopupMemory, HandleModal } from "../commonFunction";
import { useDispatch } from "react-redux";
import { activeTabAction } from "../../../redux/c_plans_slice";
import { ShowExamModelAction } from "../../../redux/global_values";

const ExerciseModal = ({ onCancel }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto outline-none focus:outline-none">
        <div className="relative w-auto max-w-3xl mx-auto my-6">
          {/*content*/}
          <div className="relative flex flex-col mx-5 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
            <div className="px-10 py-5  max-md:px-5">
              <div className="flex justify-center">
                <img
                  src="/images/dashboard/exercise.png"
                  alt="exercise"
                  className=""
                />
              </div>
              <div>
                <div className="z-10 self-center uppercase flex justify-center text-4xl max-md:text-2xl font-bebas tracking-[1px] mt-4 text-green-600">
                  Complete the exercise
                </div>
                <div className="self-center px-5 mx-auto mt-3 text-base leading-4 tracking-tight text-center text-neutral-800">
                  Confused about your future career? Get to know <br /> about
                  your superpowers by taking the test.
                </div>
              </div>
              <div className="flex justify-center w-full gap-3 mt-5 text-sm">
                <button
                  onClick={onCancel}
                  className="gap-2.5 self-stretch px-7 py-4 text-blue-600 whitespace-nowrap rounded-2xl border border-blue-600 border-solid min-h-[55px] max-md:px-5"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    onCancel();
                    dispatch(activeTabAction(2));
                  }}
                  className="gap-2.5 self-stretch px-7 py-4 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] min-h-[55px] max-md:px-5"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-30"></div>
    </>
  );
};

export default ExerciseModal;
