import React, { useState } from 'react'
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import Button from "../components/Button";
import * as Yup from "yup";
import { userDelete, userLogin } from '../axios/axios';
import { toast } from 'react-toastify';
import { useAuth } from '../authContext/AuthContext';
import { IoMdMail } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { imageLink } from '../auth/components/commonFunction';
import { FaUser } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters long.")
        .required("Password is required."),
});


const DeleteAccount = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { login, logout } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState();
    const [handleModal, setHandleModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            await userLogin({ email: values.email, password: values.password })
                .then((response) => {
                    console.log("Login successful:", response);
                    let token = response.data.token;
                    const user = response.data.user;
                    console.log("user login");
                    setUser(user)
                    login(user, token, 1);
                    setLoading(false);
                    toast.success(response.data.message);
                })
                .catch((err) => {
                    console.error("Login failed:", err);
                    setLoading(false);
                    toast.error("Invalid email or password. Please try again.");
                });
        },
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDeleteUser = async () => {
        try {
            const result = await userDelete(user._id);
            console.log(result);
            if (result && result.data) {
                setUser('');
                logout();
                navigate("/login");
                console.log("Delete successful:", result);
                toast.success("Account Deleted SuccessFull.")
            }

        } catch (error) {
            setUser('');
            logout();
            navigate("/login");
            setLoading(false);
            // console.log("Delete successful:");
            toast.success("Account Deleted SuccessFull.")
        }
    };

    return (
        <>
            {handleModal &&
                <>
                    <div className="justify-center items-center  flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-3xl shadow-lg mx-5 relative flex flex-col p-3 bg-white outline-none focus:outline-none max-w-[500px]">
                                <div className=" mt-3 text-2xl font-bebas max-md:text-center tracking-wider leading-snug text-slate-950 max-md:max-w-full max-md:text-xl">
                                    Are you sure to delete your My Future Capacity Account?
                                </div>
                                <div className='flex justify-between items-center mt-5'>
                                    <Button onClick={() => setHandleModal(false)} bColor='secondary' style={"w-[45%]"} text={"cancel"} />
                                    <Button onClick={handleDeleteUser} text={"Confirm"} style={"w-[45%]"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
                </>}
            <div
                style={{
                    background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                }}
                className="flex justify-center items-center min-h-[100vh] relative min-w-[320px]"
            >
                {!user ?
                    <div className="flex bg-white p-5 rounded-lg flex-col lg:mx-[10%] lg:w-[30%] w-[90%] mt-[7%]">
                        <img
                            loading="lazy"
                            src="/images/auth/mainLogo.png"
                            className="self-center max-w-full aspect-[0.93] w-[100px]"
                        />
                        <div className="self-center font-bebas text-4xl max-md:text-3xl mt-2.5 text-green-600 tracking-[2.55px]">
                            Login
                        </div>

                        <form onSubmit={formik.handleSubmit}>
                            <Box
                                component="form"
                                sx={{
                                    "& .MuiTextField-root": { marginTop: 1.5 },
                                    "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after":
                                    {
                                        borderBottom: "none !important",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        fontSize: "14px",
                                    },
                                    "& .MuiFormLabel-root": {
                                        padding: "15px 3px 0px 21px",
                                        fontSize: "17px",
                                        color: "black",
                                    },
                                    "& .MuiInputBase-root": {
                                        padding: "10px 0px 3px 15px",
                                    },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="Email"
                                    placeholder="johndoe@gmail.com"
                                    name="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    // onKeyDown={handleKeyDown}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                                                <IoMdMail />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    className="bg-gray-200 w-full rounded-xl pt-10"
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                                        {formik.errors.email}
                                    </FormHelperText>
                                )}
                                <TextField
                                    variant="standard"
                                    label="Password"
                                    placeholder="***********"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    // onKeyDown={handleKeyDown}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="bg-gray-200 w-full rounded-xl pt-10"

                                />
                                {formik.touched.password && formik.errors.password && (
                                    <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                                        {formik.errors.password}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Button style={"w-full mt-5"} Buttontype={"submit"} loading={loading} text={"Login"} />
                        </form>
                    </div>
                    :
                    <div className="flex justify-center items-center bg-white p-5 rounded-lg flex-col lg:mx-[10%] lg:w-[40%] w-[90%] mt-[7%]">
                        <div className='relative w-32 h-32 rounded-full border-4 border-gray-300 max-md:w-24 max-md:h-24'>
                            {user?.profilePhoto ? (
                                <img
                                    src={user?.profilePhoto && imageLink(user?.profilePhoto)}
                                    alt="Profile"
                                    className="object-cover w-full h-full rounded-full"
                                />
                            ) : (
                                <div className="flex items-center justify-center w-full h-full bg-gray-100 rounded-full">
                                    <FaUser className="text-gray-400 text-3xl" />
                                </div>
                            )}
                        </div>
                        <div className="flex flex-wrap gap-1.5">
                            <div className="text-2xl font-bebas text-green-600">
                                {user?.name ? user?.name : user.name}
                            </div>
                            <div className=" text-sm mt-1  text-purple-500 basis-auto">
                                {user?.uniqueId && `(id: ${user?.uniqueId})`}
                            </div>
                        </div>
                        <div className="text-xl font-bebas ">
                            {user.email}
                        </div>
                        <div className="text-base">
                            Subscription Status:
                            {user.subscription_status == "active" ? " Active" : "Expired"}
                        </div>
                        <Button onClick={() => setHandleModal(true)} style={"mt-3"} text={"Delete Account"} />
                    </div>}

            </div>
        </>
    )
}

export default DeleteAccount