import React, { useState, useEffect } from "react";
import AddInterest from "./components/AddInterest";
import HeaderDash from "../components/HeaderDash";
import { FaPen } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { FaSchool } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { INTERESTDATA } from "./components/interestData";
import { IoBagRemoveSharp } from "react-icons/io5";

import AddShowcase from "./components/AddShowcase";
import AddEditAchievements from "./components/AddEditAchievements";
import OtherDocuments from "./components/OtherDocuments";
import { FiPlusCircle } from "react-icons/fi";
import EditAbout from "./components/EditAbout";
import { useAuth } from "../../authContext/AuthContext";
import { CallingAxios, imageLink } from "../../auth/components/commonFunction";
import {
  downloadProfileAsPdf,
  GetAssessment,
  GetBDNA5Score,
  GetHiringInsights,
  GetPerformanceGuide,
  GetUniqueStyle,
  StoreDocuments,
  UpdateAboutUs,
  UploadProfilePicture,
  UseGetUserDetail,
} from "../../axios/axios";
import { toast } from "react-toastify";
import {
  DeleteAchievement,
  EditAchievement,
  StoreAchievement,
  StoreInterest,
} from "../../axios/profileApis";
import CustomDNAGraph from "../../components/CustomDNAGraph";
import SingleMicrocredentials from "../../components/Microcredentials";
const MyProfile = ({ setActiveTab, readOnly }) => {
  const { user } = useAuth();
  const [userDetail, setUserDetail] = useState({
    name: "",
    profilePhoto: "",
    sharedProfileLink: "",
    tagline: "",
    uniqueId: "",
    user: "",
    _id: "",
    about: "",
    batch: "",
  });
  const [schoolDetail, setSchoolDetail] = useState({ name: "", district: "" });
  const [addInterest, setAddInterest] = useState(false);
  const [addShowcase, setAddShowcase] = useState(false);
  const [addAchiements, setAddAchiements] = useState(false);
  const [showOtherDocuments, setShowOtherDocuments] = useState(false);
  const [editAchievement, setEditAchievement] = useState(null);
  const [editAbout, setEditAbout] = useState(false);
  const [saveProfile, setSaveProfile] = useState(true);
  const [internshipMentorship, setInternshipMentorship] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [profileImage, setProfileImage] = useState(null);
  const [interests, setInterests] = useState([]);
  const [showcase, setShowcase] = useState([]);
  const [achievement, setAchievement] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [chagne, setChagne] = useState(false);
  // Fetching DNA data
  const [bDNA5Score, setBDNA5Score] = useState({});
  const [industries, setIndustries] = useState([]);
  const [microcredentials, setMicrocredentials] = useState([]);
  const [downloadField, setDownloadField] = useState([
    {
      label: "All",
      value: "all",
      isChecked: false,
    },
    {
      label: "About You",
      value: "about_us",
      isChecked: false,
    },
    {
      label: "Career Interest",
      value: "career_interest",
      isChecked: false,
    },
    {
      label: "Showcase",
      value: "showcase",
      isChecked: false,
    },
    {
      label: "Achievements",
      value: "achievements",
      isChecked: false,
    },
    // {
    //   label: "Other Documents",
    //   isChecked: false,
    // },
    // {
    //   label: "Behavioral Superpower",
    //   isChecked: false,
    // },
    // {
    //   label: "Industries Based On Superpowers",
    //   isChecked: false,
    // },
    {
      label: "Microcredentials",
      value: "microcredentials",
      isChecked: false,
    },
    // {
    //   label: "Endorsed By",
    //   isChecked: false,
    // },
  ]);
  useEffect(() => {
    callGetUserDetail();
  }, [chagne]);

  useEffect(() => {
    callGetAssessment();
    callGetUniqueStyle();
    callGetHiringInsights();
    callGetBDNA5Score();
    callGetPerformanceGuide();
  }, []);

  const callGetUserDetail = async () => {
    try {
      const result = await CallingAxios(UseGetUserDetail(user?.candidateId));
      if ((result && result.Status == 200) || result.schoolInfo.length > 0) {
        setUserDetail({
          ...userDetail,
          ...result.data.userProfile,
        });
        setSchoolDetail(result.schoolInfo);
        setInterests(result.data.userProfile.interests);
        setAchievement(result.data.userProfile.achievements);
        setIndustries(result.data.industries);
        setMicrocredentials(result.data.microcredentials);
        setShowcase(result.data.showcasePosts);
        // setOtherDocuments(result.data.userProfile.documents);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onSaveAbout = (data) => {
    try {
      CallingAxios(UpdateAboutUs(user?.candidateId, data)).then((res) => {
        setAboutData(data);
        setUserDetail((prev) => ({
          ...prev,
          about: data.about,
          tagline: data.tagline,
        }));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      handleProfilePicture(file);
    }
  };
  const handleSaveInterests = async (selectedItems) => {
    await CallingAxios(
      StoreInterest({ interest: selectedItems })
        .then((res) => {
          if (res.status === 200) {
            setInterests(selectedItems);
            setChagne(!chagne);
          }
        })
        .catch((error) => {
          toast.error("Error storing interests");
        })
    );
  };

  const handleProfilePicture = async (file) => {
    await CallingAxios(
      UploadProfilePicture({ file, candidateId: user?.candidateId })
        .then((res) => {
          setUserDetail((prev) => ({
            ...prev,
            profilePhoto: res.data.profilePhoto,
          }));
        })
        .catch((error) => {
          console.log(error);
        })
    );
  };

  const handleSaveShowcase = (selectedItems) => {
    // Fetch and update the showcase based on selectedItems
    setShowcase(selectedItems);
    try {
      const data = {
        showcaseIds: JSON.stringify(selectedItems),
      };
      CallingAxios(UpdateAboutUs(user?.candidateId, data)).then((res) => {
        console.log(res);
        // setAddShowcase(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveAchievement = async (updatedData) => {
    try {
      const data = await CallingAxios(
        StoreAchievement({
          achievement: updatedData,
        })
      );
      setAchievement((prevData) => [...prevData, data.data.data]);
      setEditAchievement(null);
      setAddAchiements(false);
      setChagne(!chagne);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackToProfile = () => {
    setEditAchievement(null);
    setAddAchiements(false);
  };

  const handleDeleteAchievement = async (id) => {
    try {
      await CallingAxios(DeleteAchievement({ achievementId: id }));
      setAchievement((prevData) => prevData.filter((item) => item._id !== id));
      setEditAchievement(null); // Clear edit state
      setAddAchiements(false); // Close the modal
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditAchievement = async (updatedData) => {
    try {
      await CallingAxios(
        EditAchievement({ achievementId: updatedData._id, updatedData })
      );
      setEditAchievement(null);
      setAddAchiements(false);
      setChagne(!chagne);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditClick = (achievement) => {
    setEditAchievement(achievement);
    setAddAchiements(true); // Open modal for editing
  };

  const getFileIcon = (type) => {
    if (type.includes("pdf")) return "/images/dashboard/profile/pdf.png";
    if (type.includes("word")) return "/images/dashboard/profile/word.png";
    return "/images/dashboard/profile/default.png";
  };

  const handleFilesSave = (files) => {
    handleStoreDocuments(files);
    setOtherDocuments((prevData) => {
      const existingFiles = new Set(prevData.map((file) => file.name));
      const newFiles = files.filter((file) => !existingFiles.has(file.name));
      return [...prevData, ...newFiles];
    });
    setShowOtherDocuments(false);
  };

  const handleCancel = () => {
    setShowOtherDocuments(false);
  };

  const callGetAssessment = async () => {
    await CallingAxios(
      GetAssessment()
        .then((res) => {
          // setAssessment(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    );
  };

  const callGetUniqueStyle = async () => {
    await CallingAxios(
      GetUniqueStyle()
        .then((res) => {
          // setUniqueStyle(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    );
  };

  const callGetHiringInsights = async () => {
    await CallingAxios(
      GetHiringInsights()
        .then((res) => {
          // setHiringInsights(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    );
  };

  const callGetBDNA5Score = async () => {
    await CallingAxios(
      GetBDNA5Score()
        .then((res) => {
          const formattedData = {
            abstractVsConcrete: {
              value: res.data.abstractVsConcrete.value,
              description: res.data.abstractVsConcrete.description,
              label: res.data.abstractVsConcrete.label,
            },
            daringVsCareful: {
              value: res.data.daringVsCareful.value,
              description: res.data.daringVsCareful.description,
              label: res.data.daringVsCareful.label,
            },
            promotingVsOperating: {
              value: res.data.promotingVsOperating.value,
              description: res.data.promotingVsOperating.description,
              label: res.data.promotingVsOperating.label,
            },
            resultsVsRelationships: {
              value: res.data.resultsVsRelationships.value,
              description: res.data.resultsVsRelationships.description,
              label: res.data.resultsVsRelationships.label,
            },
            systematicVsFlexible: {
              value: res.data.systematicVsFlexible.value,
              description: res.data.systematicVsFlexible.description,
              label: res.data.systematicVsFlexible.label,
            },
          };
          setBDNA5Score(formattedData);
        })
        .catch((error) => {
          console.log(error);
        })
    );
  };

  const callGetPerformanceGuide = async () => {
    await CallingAxios(
      GetPerformanceGuide()
        .then((res) => {
          // setPerformanceGuide(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    );
  };

  const handleSectionSelect = (section, isChecked) => {
    if (section.label === "All") {
      setDownloadField(
        downloadField.map((s) => ({ ...s, isChecked: !isChecked }))
      );
    } else {
      setDownloadField((prev) =>
        prev.map((s) =>
          s.label === section.label ? { ...s, isChecked: !isChecked } : s
        )
      );
    }
  };

  const handleDownload = async () => {
    const data = {};
    downloadField.forEach((s) => {
      data[s.value] = s.isChecked;
    });
    try {
      const response = await fetch(
        `https://dev-futurecapacity-api.flynautstaging.com/download-profile-as-pdf/${user?.candidateId}`,
        {
          method: "POST", // Adjust based on your backend
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            all: data.all,
            about_us: data.about_us,
            career_interest: data.career_interest,
            showcase: data.showcase,
            microcredentials: data.microcredentials,
            achievements: data.achievements,
          }), // Sending required body
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);

      // Open in a new tab
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleStoreDocuments = async (files) => {
    console.log(user?.candidateId, files);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("documents", file);
    });
    CallingAxios(StoreDocuments(user?.candidateId, formData))
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="p-4 min-w-[320px]">
      <HeaderDash setActiveTab={setActiveTab} />
      {editAbout ? (
        <EditAbout
          setEditAbout={setEditAbout}
          onSaveAbout={onSaveAbout}
          userDetail={userDetail}
          setUserDetail={setUserDetail}
        />
      ) : addInterest ? (
        <AddInterest
          setInterest={setAddInterest}
          onSave={handleSaveInterests}
        />
      ) : addShowcase ? (
        <AddShowcase
          setAddShowcase={setAddShowcase}
          onSave={handleSaveShowcase}
          user={user}
        />
      ) : addAchiements ? (
        <AddEditAchievements
          setAddAchiements={setAddAchiements}
          onSave={handleSaveAchievement}
          achievement={editAchievement}
          onDelete={handleDeleteAchievement}
          onEdit={handleEditAchievement}
          onBack={handleBackToProfile}
        />
      ) : showOtherDocuments ? (
        <OtherDocuments onFilesSave={handleFilesSave} onCancel={handleCancel} />
      ) : (
        <div className="mt-10">
          <div className="flex justify-between">
            <div className="flex flex-wrap items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600 w-fit">
              <img
                loading="lazy"
                src="/images/dashboard/quote-right.png"
                className="object-contain shrink-0 self-start -mt-3 mr-[1px]  aspect-square w-[25px]"
              />
              <div className="flex-auto self-start max-md:max-w-full">
                Dream big, strive for greatness!
              </div>
              <img
                loading="lazy"
                src="/images/dashboard/quote-left.png"
                className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
              />
            </div>
            {!readOnly && (
              <div>
                {saveProfile ? (
                  <div className="flex gap-2">
                    <button
                      onClick={() => setIsModalOpen(true)}
                      className="px-3 pt-3.5 pb-3 flex gap-1 max-w-full w-44  text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
                    >
                      <MdOutlineFileDownload className="text-xl" /> Download
                      Profile
                    </button>
                    <button
                      onClick={() => setSaveProfile(false)}
                      className="px-3 pt-3.5 pb-3 max-w-full w-44 text-[15px] text-center text-blue-600 border-[1px] border-blue-600 whitespace-nowrap rounded-lg "
                    >
                      Edit Profile
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setSaveProfile(true)}
                    className="px-3 pt-3.5 pb-3 max-w-full w-44 text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
                  >
                    Save Profile
                  </button>
                )}
              </div>
            )}
            {readOnly && (
              <div>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-3 pt-3.5 pb-3 flex gap-1 max-w-full w-44  text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
                >
                  <MdOutlineFileDownload className="text-xl" /> Download Profile
                </button>
              </div>
            )}
          </div>
          <div className="mt-9 max-md:mr-1 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-5 max-md:max-w-full">
                  <div
                    style={{
                      boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)",
                    }}
                    className="flex flex-col px-7 py-5 w-full bg-white rounded-xl max-md:px-5 max-md:mr-0.5 max-md:max-w-full"
                  >
                    <div className="max-md:max-w-full">
                      <div className="flex gap-y-5 gap-x-10 max-md:flex-col">
                        <div className="flex flex-col w-[22%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col">
                            <div className="flex flex-col justify-center items-center">
                              <div className="flex relative flex-col justify-center items-center">
                                <div className="relative w-28 h-28 rounded-full border-4 border-gray-300 max-md:w-24 max-md:h-24">
                                  {user?.profilePhoto || profileImage ? (
                                    <img
                                      src={
                                        profileImage
                                          ? profileImage
                                          : user?.profilePhoto &&
                                            imageLink(user?.profilePhoto)
                                      }
                                      alt="Profile"
                                      className="object-cover w-full h-full rounded-full"
                                    />
                                  ) : (
                                    <div className="flex justify-center items-center w-full h-full bg-gray-100 rounded-full">
                                      <FaUser className="text-3xl text-gray-400" />
                                    </div>
                                  )}
                                  {saveProfile ? (
                                    <div className="relative">
                                      <div
                                        onClick={() =>
                                          setInternshipMentorship(
                                            !internshipMentorship
                                          )
                                        }
                                        className="absolute right-0 bottom-0 z-20 p-1 text-blue-500 bg-white rounded-full border-2 border-blue-500 transform translate-y-1 cursor-pointer"
                                      >
                                        <IoBagRemoveSharp className="text-[15px]" />
                                      </div>
                                      <div
                                        className={`absolute top-3 md:left-0 max-md:-right-20 z-50 text-black bg-white p-4 shadow-2xl rounded-xl w-[250px] ${
                                          internshipMentorship ? "":"hidden"}`}
                                      >
                                        <div className="flex gap-2">
                                          <div className="flex z-20 justify-center items-center p-1 w-11 h-8 text-blue-500 bg-white rounded-full border-2 border-blue-500 cursor-pointer">
                                            <IoBagRemoveSharp className="text-[15px]" />
                                          </div>
                                          <div>
                                            <div className="">
                                              Open to internship
                                            </div>
                                            <div className="mt-1 text-sm text-opacity-40 text-slate-950">
                                              Lorem ipsum dolor sit amet
                                              consectetur.
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex gap-2 mt-3">
                                          <div className="flex z-20 justify-center items-center p-1 w-11 h-8 text-blue-500 bg-white rounded-full border-2 border-blue-500 cursor-pointer">
                                            <FaUser className="text-[15px]" />
                                          </div>
                                          <div>
                                            <div className="">
                                              Open to mentorship
                                            </div>
                                            <div className="mt-1 text-sm text-opacity-40 text-slate-950">
                                              Lorem ipsum dolor sit amet
                                              consectetur.
                                            </div>
                                          </div>
                                        </div>
                                        <button className="px-3 pt-3.5 pb-3 w-full max-w-full mt-3 text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]">
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <label
                                      htmlFor="profileImageInput"
                                      className="absolute right-0 bottom-0 z-20 p-1 text-blue-500 bg-white rounded-full border-2 border-blue-500 transform translate-y-1 cursor-pointer"
                                    >
                                      <FaPen className="text-[15px]" />
                                    </label>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="profileImageInput"
                                  className="absolute top-0 left-0 w-full h-full opacity-0"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                              </div>
                              <button
                                style={{
                                  background:
                                    "linear-gradient(90deg, #F79E1B 50%, #9A52DE 97.52%)",
                                }}
                                className={`mt-3 rounded-2xl text-white text-[14px] w-fit px-3 py-1 ${
                                  saveProfile ? "hidden" : ""}`}
                              >
                                Add Batch
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-[78%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col items-start mt-1 w-full max-md:mt-10 max-md:max-w-full">
                            <div className="flex gap-5 justify-between w-full max-md:max-w-full">
                              <div className="flex flex-wrap gap-1.5">
                                <div className="text-2xl text-green-600 font-bebas">
                                  {userDetail?.name
                                    ? userDetail?.name
                                    : user.name}
                                </div>
                                <div className="mt-1 text-sm text-purple-500 basis-auto">
                                  {userDetail?.uniqueId &&
                                    `(id: ${userDetail?.uniqueId})`}
                                </div>
                              </div>
                              <div
                                onClick={() => setEditAbout(true)}
                                className={` border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer ${
                                  saveProfile ? "hidden" : ""}`}
                              >
                                <FaPen className="text-[12px] " />
                              </div>
                            </div>
                            <div className="flex gap-2.5 mt-1.5 text-sm text-slate-950">
                              <FaSchool className="text-[19px]" />
                              <div className="basis-auto">
                                {schoolDetail?.name}
                              </div>
                            </div>
                            <div className="mt-1 text-sm tracking-wide text-opacity-40 text-slate-950">
                              {aboutData.tagLine}
                            </div>
                            <div className="shrink-0 mt-3 h-px border border-solid border-neutral-400 w-[47px]" />
                            <div className="flex gap-1.5 mt-4 text-xl text-green-600">
                              <div className="font-bebas">ABOUT ME</div>
                              <div className="self-start mt-4 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                            </div>
                            <div className="mt-1 text-sm font-medium text-slate-950">
                              {userDetail?.about}
                            </div>
                            <div className="flex-auto text-sm leading-5 text-neutral-400 max-md:max-w-full">
                              {aboutData.aboutMe}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)",
                    }}
                    className="flex flex-col items-start px-5 py-11 mt-5 w-full bg-white rounded-xl max-md:max-w-full"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-1.5 text-2xl text-green-600">
                        <div className="font-bebas">CAREER INTEREST</div>
                        <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                      </div>
                      {interests.length > 0 ? (
                        <div
                          onClick={() => setAddInterest(true)}
                          className={`max-md:mt-1 border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer ${
                            saveProfile ? "hidden" : ""}`}
                        >
                          <FaPen className="text-[12px] " />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {interests.length > 0 ? (
                      ""
                    ) : (
                      <div className="text-sm leading-loose text-neutral-400">
                        Add your interests that will be highlighted in your
                        profile.
                      </div>
                    )}
                    <div>
                      <div className="grid grid-cols-4 gap-4 mt-4 w-full max-sm:grid-cols-2">
                        {interests.length > 0 ? (
                          interests.map((elem, ind) => {
                            const item = INTERESTDATA.find(
                              (data) => data.name === elem
                            );
                            return (
                              item && (
                                <div
                                  key={ind}
                                  style={{
                                    background: item.bg,
                                    position: "relative",
                                  }}
                                  className="flex flex-col justify-center items-center w-20 h-20 rounded-xl cursor-pointer"
                                >
                                  <img
                                    src={item.image}
                                    alt={item.name}
                                    className="max-w-12 max-md:max-w-10"
                                  />
                                  <div className="text-[#fff] mt-1 text-xs">
                                    {item.name}
                                  </div>
                                </div>
                              )
                            );
                          })
                        ) : !readOnly ? (
                          <button
                            onClick={() => setAddInterest(true)}
                            className="mt-4 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-20 h-20 max-md:mt-5"
                          >
                            +
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex justify-between mt-16 w-full max-md:mt-10">
                      <div className="flex gap-1.5 text-2xl text-green-600 whitespace-nowrap">
                        <div className="font-bebas">SHOWCASE</div>
                        <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                      </div>
                      {showcase.length > 0 ? (
                        <div
                          onClick={() => setAddShowcase(true)}
                          className={` max-md:mt-1 border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer ${
                            saveProfile ? "hidden" : ""}`}
                        >
                          <FaPen className="text-[12px] " />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {showcase.length > 0 ? (
                      ""
                    ) : (
                      <div className="text-sm leading-loose text-neutral-400">
                        Add your showcase that will be highlighted in your
                        profile.
                      </div>
                    )}

                    <div className="w-full">
                      <div className="flex flex-col gap-2 mt-4 w-full">
                        {showcase.length > 0
                          ? showcase.map(
                              (item) =>
                                item && (
                                  <div
                                    style={{
                                      boxShadow:
                                        "0px 0px 19px 0px rgba(0, 0, 0, 0.12)",
                                    }}
                                    className="flex relative flex-wrap gap-3 p-2.5 w-full max-w-full text-sm bg-white rounded-2xl max-h-[200px] overflow-y-auto"
                                  >
                                    <div className="flex gap-3 max-md:flex-col">
                                      <img
                                        loading="lazy"
                                        src={imageLink(item.media[0])}
                                        className="object-contain self-start w-20 rounded-2xl shrink-0 aspect-square"
                                      />
                                      <div className="flex flex-col self-start grow shrink-0 basis-0 w-fit max-md:max-w-full">
                                        <div className="tracking-normal text-black max-md:max-w-full">
                                          {item.content}
                                        </div>
                                        <div className="flex gap-1.5 self-start mt-2 font-medium text-purple-600">
                                          <img
                                            loading="lazy"
                                            src={imageLink(item.profilePic)}
                                            className="object-contain shrink-0 aspect-square rounded-full overflow-hidden w-[25px]"
                                          />
                                          <div className="my-auto">
                                            {user.name}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )
                          : ""}
                      </div>
                      {showcase.length > 0 ? (
                        ""
                      ) : (
                        <button
                          onClick={() => setAddShowcase(true)}
                          className="mt-4 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-full h-20"
                        >
                          +
                        </button>
                      )}
                    </div>
                    <div className="flex gap-1.5 justify-between mt-16 w-full text-2xl text-green-600 whitespace-nowrap max-md:mt-10">
                      <div className="flex gap-1.5">
                        <div className="font-bebas">ACHIEVEMENTS</div>
                        <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                      </div>
                      {achievement && Object.keys(achievement).length > 0 ? (
                        <button
                          onClick={() => setAddAchiements(true)}
                          className={` text-blue-600 text-xl ${
                            saveProfile ? "hidden" : ""}`}
                        >
                          <FiPlusCircle />
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {achievement.length > 0 ? (
                      achievement.map((achievement, index) => (
                        <div
                          key={achievement.id}
                          className="relative flex justify-between p-3 mt-4 w-full bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] max-md:px-5 max-md:max-w-full"
                        >
                          <div className="flex relative gap-6 w-full">
                            <img
                              loading="lazy"
                              src="/images/dashboard/profile/medal.png"
                              className="object-contain z-10 shrink-0 my-auto aspect-[0.66] w-[40px]"
                            />
                            <div className="flex flex-col items-start w-full max-md:max-w-full">
                              <div className="flex self-end w-full rounded-full shrink-0 bg-stone-50" />
                              <div className=" z-10 text-[15px] leading-none text-black max-md:max-w-full w-full">
                                {achievement.title}
                              </div>
                              <div className="flex gap-5 justify-between mt-1 max-w-full text-sm leading-loose text-neutral-400">
                                <div className="z-20 self-start">
                                  {formattedDate}
                                </div>
                                <div className="absolute bottom-2 right-24 flex shrink-0 rounded-full bg-stone-50 h-[40px] w-[40px]" />
                                <div className="absolute top-2 right-10 flex shrink-0 rounded-full bg-stone-50 h-[20px] w-[20px]" />
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => handleEditClick(achievement)}
                            className={` max-md:mt-1 self-end border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer ${
                              saveProfile ? "hidden" : ""}`}
                          >
                            <FaPen className="text-[12px] " />
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="w-full">
                        <div className="text-sm leading-loose text-neutral-400">
                          Add your achievements that will be highlighted in your
                          profile.
                        </div>
                        <button
                          onClick={() => setAddAchiements(true)}
                          className="mt-7 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-full h-20"
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
                className="flex flex-col w-6/12 bg-white rounded-xl max-md:w-full"
              >
                <div className="flex flex-col items-start pt-7 pr-14 pl-5 mx-auto w-full text-2xl text-green-600 bg-white rounded-xl max-md:pr-5 max-md:mt-5 max-md:max-w-full">
                  <div className="flex gap-1.5">
                    <div className="font-bebas">Behavioral Superpower </div>
                    <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                  </div>
                  <div className="text-sm leading-loose text-neutral-400 max-md:max-w-full">
                    {bDNA5Score ? (
                      <>
                        Below are your behavioral superpowers based on your
                        assessment.
                      </>
                    ) : (
                      <>
                        Your behavioral superpowers will be added here after you
                        take the{" "}
                        <span className="font-medium text-blue-600">
                          assessment
                        </span>
                        .
                      </>
                    )}
                  </div>
                  <div className="flex flex-col gap-2 justify-center">
                    {Object.keys(bDNA5Score).length > 0 ? (
                      Object.keys(bDNA5Score).map((key) => (
                        <CustomDNAGraph
                          value={bDNA5Score[key].value}
                          description={bDNA5Score[key].description}
                          label={key}
                        />
                      ))
                    ) : (
                      <img
                        loading="lazy"
                        src="/images/dashboard/profile/assessment.png"
                        className="object-contain mt-3.5 max-w-full aspect-[1.31] w-[272px]"
                      />
                    )}
                  </div>

                  <div className="flex gap-1.5 mt-9">
                    <div className="font-bebas">
                      Industries BASED ON SUPERPOWERS
                    </div>
                    <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                  </div>
                  {industries.length < 1 && (
                    <div className="self-stretch mt-1 text-sm leading-loose text-neutral-400 max-md:max-w-full">
                      Your internships based on superpowers will be added here
                      after you take the{" "}
                      <span className="font-medium text-blue-600">
                        assessment.{" "}
                      </span>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-4 max-md:grid-cols-1">
                    {industries.map((industry) => (
                      <div
                        key={industry.id}
                        className="flex p-2 gap-2 rounded-xl border-l-4 bg-stone-50 border-[#1D68FF] max-md:w-full"
                      >
                        <img
                          src={imageLink(industry.names?.[0].image)}
                          className="w-[50px] h-[50px] my-auto"
                        />
                        <div className="flex flex-col">
                          <p className="text-lg font-medium text-black">
                            {industry.names?.[0].name}
                          </p>
                          <p className="text-sm text-neutral-400">
                            {industry.names?.[0].description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-1.5 mt-8 whitespace-nowrap max-md:mt-10">
                    <div className="font-bebas"> MICROCREDENTIALS</div>
                    <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                  </div>
                  <div className="self-stretch mt-1 text-sm leading-loose text-neutral-400 max-md:mr-1 max-md:max-w-full">
                    Your microcredentials will be added here after you complete
                    the test. Go to
                    <span className="font-medium text-blue-600">
                      {" "}
                      Microcredentials.
                    </span>
                  </div>
                  <div className="overflow-y-auto max-h-[200px] custom-scrollbar">
                    {microcredentials.map((microcredential) => (
                      <SingleMicrocredentials
                        key={microcredential.id}
                        item={microcredential}
                        onClick={() => {}}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="flex flex-col px-6 py-6 mt-5 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:mr-1 max-md:max-w-full"
          >
            <div className="flex justify-between">
              <div className="flex gap-1.5 self-start text-2xl text-green-600 whitespace-nowrap">
                <div className="font-bebas">Other Documents</div>
                <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
              </div>
              {saveProfile ? (
                <button className="px-3 pt-3.5 pb-3 max-w-full  text-[15px] text-center text-blue-600 border-[1px] border-blue-600 whitespace-nowrap rounded-lg ">
                  Connect Your Transcripts
                </button>
              ) : (
                <div>
                  {otherDocuments.length > 0 ? (
                    <button
                      onClick={() => setShowOtherDocuments(true)}
                      className="text-xl text-blue-600"
                    >
                      <FiPlusCircle />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            {otherDocuments.length > 0 ? (
              ""
            ) : (
              <button
                onClick={() => setShowOtherDocuments(true)}
                className="mt-7 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-full h-20"
              >
                +
              </button>
            )}
            <div>
              <div className="flex flex-wrap gap-4 mt-4">
                {otherDocuments.map((file, index) => (
                  <div
                    key={index}
                    className="flex flex-col pt-1.5 w-32 rounded-none max-md:w-28"
                  >
                    <div className="flex flex-col items-center pb-2 h-32 bg-sky-200 bg-opacity-20 rounded-xl max-md:h-28">
                      <div className="flex flex-col justify-center items-center self-stretch h-[65px] max-md:h-[55px] bg-white rounded-xl border-4 border-sky-50 border-solid">
                        <img
                          loading="lazy"
                          src={getFileIcon(file.type)}
                          className="w-[20px] max-md:w-[16px]"
                        />
                      </div>
                      <div className="text-xs leading-snug text-black px-1 pt-1 max-md:text-[11px] ">
                        {file.name}
                      </div>
                      <div className="px-2 text-xs leading-snug text-stone-500">
                        {file.size}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalOpen ? (
        <>
          <div
            style={{ scrollbarWidth: "none" }}
            className="flex overflow-y-auto overflow-x-hidden fixed inset-0 z-50 justify-center items-center pt-7 outline-none focus:outline-none max-md:mx-3"
          >
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="flex relative flex-col w-full bg-white rounded-2xl border-0 shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col rounded-none">
                  <div className="flex flex-col pt-7 pb-16 w-full bg-white rounded-3xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-end mr-6 h-8 w-8 bg-white rounded-2xl shadow-[0px_20px_50px_rgba(0,0,0,0.12)] max-md:mr-2.5">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="text-blue-600"
                      >
                        &#10005;
                      </button>
                    </div>
                    <div className="flex flex-col px-10 mt-1 w-full text-center max-md:mt-10 max-md:max-w-full">
                      <div className="text-2xl uppercase text-[#009A39] font-bebas tracking-[0.5px] max-md:max-w-full max-md:text-xl">
                        Download Profile
                      </div>
                      <div className="text-base leading-relaxed text-neutral-400 max-md:max-w-full">
                        Select what you want to include in your profile PDF
                      </div>
                      <div className="flex flex-col w-full items-start justify-start text-base text-[#524B6B]">
                        <div className="pl-5 flex flex-col gap-3 mt-3 justify-start items-start max-md:text-[14px]">
                          {downloadField.map((section) => (
                            <div key={`${section.label}-${Math.random()}`}>
                              <input
                                type="checkbox"
                                className="w-[18px] h-[18px] my-auto"
                                checked={section.isChecked}
                                id={section.label}
                                onChange={() =>
                                  handleSectionSelect(
                                    section,
                                    section.isChecked
                                  )
                                }
                              />
                              <label className="pl-2.5" htmlFor={section.label}>
                                {section.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button
                        className="md:w-[80%] mx-auto max-md:w-full px-3 py-4 mt-10 text-sm text-white whitespace-nowrap bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] rounded-2xl "
                        onClick={handleDownload}
                      >
                        Generate Profile PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-20"></div>
        </>
      ) : null}
    </div>
  );
};

export default MyProfile;
