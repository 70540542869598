import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import HeaderDash from "../dashboard/components/HeaderDash";
import { useAuth } from "../authContext/AuthContext";
import {
  AutoRenewSubscription,
  CancelSubscriptionSchool,
  GetActiveSubscriptionSchool,
  GetCurrentSubscriptionDetails,
} from "../axios/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function SubscriptionStud({ setActiveTab }) {
  const { school } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const subscriptionPlan =
    school?.subscription_status.trim() !== "active" ? true : false;

  const [billingInfo, setBillingInfo] = useState([]);
  const [billingLoading, setBillingLoading] = useState(true);
  const [activeSubscription, setActiveSubscription] = useState({});
  const [enabled, setEnabled] = useState(
    activeSubscription.isAutoRenew || false
  );

  const navigate = useNavigate();

  const handleClick = () => {
    const data = { subscription: "school" };
    navigate("/subscriptions", { state: data });
  };

  const handleSubscriptionCancel = () => {
    CancelSubscriptionSchool({ db_id: school._id })
      .then((response) => {
        setIsModalOpen(false);
      })
      .catch((err) => {
        toast.error(err?.error);
        setIsModalOpen(false);
      });
  };

  const toggleAutoRenew = () => {
    AutoRenewSubscription({ db_id: school._id, role: "school" })
      .then((response) => {
        setEnabled(!enabled);
      })
      .catch((err) => {
        console.log(err.error);
        toast.error(err?.error);
      });
  };
  const fetchActiveSubscription = () => {
    GetActiveSubscriptionSchool({ db_id: school._id, type: "school" })
      .then((res) => {
        setBillingInfo(res.invoiceList);
        setBillingLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const fetchCurrentSubscriptionDetails = () => {
    GetCurrentSubscriptionDetails({
      db_id: school._id,
      type: "school",
      subscriptionId: school.subscription_paymentgateway_id,
    })
      .then((res) => {
        const formattedData = {
          endAt: dayjs
            .unix(res.subscription.current_period_end)
            .format("MMMM D, YYYY"),
          isAutoRenew: !res.subscription.cancel_at_period_end,
          statedAt: res.subscription.start_date,
        };
        setEnabled(formattedData.isAutoRenew);
        setActiveSubscription(formattedData);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCurrentSubscriptionDetails();
    fetchActiveSubscription();
  }, []);

  return (
    <div className="p-4 min-w-[320px]">
      <HeaderDash />
      <div className="self-center p-7 mt-8 max-w-full md:w-[80%]  bg-white rounded-3xl shadow-2xl mx-auto max-md:mr-4">
        <div className="flex justify-between w-full gap-5 max-md:flex-col max-md:w-full">
          <div className="flex md:w-[50%] gap-14 max-sm:flex-col ">
            <div className="flex flex-col text-base font-medium text-black">
              <div className="max-md:mr-2.5 whitespace-nowrap">
                Subscription Plan
              </div>
              <div className="text-sm text-neutral-400 whitespace-nowrap">
                Individual: $49/annum
              </div>
              <div className={`${subscriptionPlan ? "hidden" : ""}`}>
                <div className="self-start mt-9 whitespace-nowrap max-md:mt-3">
                  Auto Renewal
                </div>
                <div className="self-start text-sm text-neutral-400 whitespace-nowrap">
                  Lorem Ipsum Dolar
                </div>
              </div>
              <div
                className={`self-start text-sm mt-5 font-normal max-md:w-full ${
                  subscriptionPlan ? "" : "hidden"
                }`}
              >
                Sorry to see you go away, but you can always start your
                subscription anytime. Click on the button to get started.
              </div>
            </div>
            <div
              className={`flex flex-col gap-5 justify-between w-full max-md:max-w-full ${
                subscriptionPlan ? "hidden" : ""
              }`}
            >
              <div className="flex flex-col self-start">
                <div className="text-base font-medium text-black whitespace-nowrap">
                  Plan Expires
                </div>
                <div className="self-start text-sm text-neutral-400 whitespace-nowrap">
                  {activeSubscription?.endAt
                    ? new Date(activeSubscription.endAt).toLocaleDateString(
                        "en-US",
                        { month: "long", day: "numeric", year: "numeric" }
                      )
                    : "-"}
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className={`relative w-12 h-6 transition duration-200 ease-linear rounded-full ${
                    enabled ? "bg-blue-500" : "bg-gray-300"
                  }`}
                  onClick={() => toggleAutoRenew()}
                >
                  <div
                    className={`absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-linear ${
                      enabled ? "translate-x-6" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center px-4 text-base text-center text-blue-600 border-2 border-blue-600 border-solid h-14 max-md:w-full max-md:mt-2 rounded-2xl ">
            {subscriptionPlan ? (
              <button onClick={handleClick}>Start Subscription</button>
            ) : (
              <button onClick={() => setIsModalOpen(true)}>Cancel Plan</button>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center self-center md:w-[80%] mx-auto p-4 mt-6 max-w-full text-sm bg-white rounded-3xl shadow-2xl text-neutral-400 max-md:mr-4">
        <div className="flex flex-wrap gap-5 justify-between self-stretch text-2xl tracking-[1px] text-green-600 max-md:max-w-full">
          <div className="pt-3 pl-3 my-auto font-bebas">Billing info</div>
        </div>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: 700,
            boxShadow: "none",
            borderRadius: "16px",
            maxHeight: "350px",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
        >
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">ID</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>

                <StyledTableCell align="left">Invoice</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                minWidth: 500,
                whiteSpace: "nowrap",
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                },
              }}
            >
              {!billingLoading ? (
                billingInfo.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      {row.subscription_paymentgateway_id}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.createdAt).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.amount_paid}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <img
                        src="/images/dashboard/profile/pdf.svg"
                        alt="Product"
                        className="cursor-pointer"
                        onClick={() => {
                          window.open(row.invoice_pdf, "_blank");
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <h1>Loading Subscription Invoice</h1>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {isModalOpen ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none max-md:mx-3">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-2xl focus:outline-none">
                {/*header*/}
                <div className="flex flex-col rounded-none ">
                  <div className="flex flex-col w-full pb-16 bg-white pt-7 rounded-3xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-end mr-6 h-8 w-8 bg-white rounded-2xl shadow-[0px_20px_50px_rgba(0,0,0,0.12)] max-md:mr-2.5">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="text-blue-600 "
                      >
                        &#10005;
                      </button>
                    </div>
                    <div className="flex flex-col w-full px-10 mt-1 text-center max-md:mt-10 max-md:max-w-full">
                      <div className="text-2xl text-[#FF5271] font-bebas tracking-[0.5px] max-md:max-w-full max-md:text-xl">
                        CANCEL PLAN
                      </div>
                      <div className="text-base leading-relaxed text-neutral-400 max-md:max-w-full">
                        Are you sure you want to cancel your subscription?
                      </div>
                      <button
                        onClick={() => handleSubscriptionCancel()}
                        className="md:w-[80%] mx-auto max-md:w-full px-3 py-4 mt-10 text-sm text-white whitespace-nowrap bg-[#FF1942] rounded-2xl "
                      >
                        Cancel Plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-20"></div>
        </>
      ) : null}
    </div>
  );
}

export default SubscriptionStud;
