import React, { useState } from "react";
import Button from "../../components/Button";
function MentorFilter({ setIsOpen }) {
  const [filter, setfilter] = useState([{ enable: false, value: 'Influencer' },
  { enable: false, value: 'Engager' },
  { enable: false, value: 'Community Builder' },
  { enable: false, value: 'Relationship Builder' },
  { enable: false, value: 'Facilitator' },
  { enable: false, value: 'Adapter' },
  { enable: false, value: 'Stylish Thinker' },
  { enable: false, value: 'Reflective Thinker' },
  { enable: false, value: 'Strategist' },
  { enable: false, value: 'Initiator' }]);

  const CheckBo = (index) => {
    let newData = [...filter];
    newData[index] = { ...newData[index], enable: !newData[index].enable };
    console.log(newData);
    setfilter(newData);
    // dispatch(filterAction(newData))
    // setFilterValue(newData);
  }

  const reset = () => {
    let newData = filter.map((item) => {
      return { ...item, enable: false };
    });
    console.log(newData);
    setfilter(newData);
  }

  return (
    <div className="flex flex-col items-start p-10 min-h-screen bg-white">
      <div className="flex gap-5 justify-between self-stretch w-full max-md:max-w-full">
        <div className="my-auto text-2xl font-bebas tracking-widest text-neutral-700">
          FILTER
        </div>
        <img
          onClick={() => setIsOpen(false)}
          loading="lazy"
          src="/images/dashboard/celebrate/cross.png"
          className=" w-[34px] bg-gray-50 rounded-full cursor-pointer"
        />
      </div>
      <div className="mt-5 text-2xl font-bebas tracking-[1px] text-green-600">
        SUPERPOWERS
      </div>
      <div className="mt-5">
        {filter?.map((item, idx) => (
          <div key={idx} className="flex gap-3 cursor-pointer" onClick={() => CheckBo(idx)}>
            <input checked={item.enable} type="checkbox" className="w-4 h-4 my-auto" />
            <div>{item?.value}</div>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap gap-5 self-center mt-10 max-md:max-w-full">
        <Button onClick={reset} bColor="secondary" text="Reset" />
        <Button text="Apply Now" />
      </div>
    </div>
  );
}
export default MentorFilter;
