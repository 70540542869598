export const INTERESTDATA = [
  {
    id: 1,
    bg: "linear-gradient(158deg, #725DF0 2.05%, #1D68FF 85.63%)",
    image: "/images/dashboard/profile/robotics.png",
    name: "Robotics",
  },
  {
    id: 2,
    bg: "linear-gradient(156deg, #DB50DB 5%, #8E06D8 84.61%)",
    image: "/images/dashboard/profile/engineering.png",
    name: "Engineering",
  },
  {
    id: 3,
    bg: "linear-gradient(180deg, #FF55A3 0%, #D01269 100%)",
    image: "/images/dashboard/profile/metallurgy.png",
    name: "Metallurgy",
  },
  {
    id: 4,
    bg: "linear-gradient(158deg, #DB50DB 2.05%, #772077 85.63%)",
    image: "/images/dashboard/profile/art.png",
    name: "Art",
  },
  {
    id: 5,
    bg: "linear-gradient(156deg, #FF55A3 5%, #CD397D 84.61%)",
    image: "/images/dashboard/profile/basket-ball.png",
    name: "Basketball",
  },
  {
    id: 6,
    bg: "linear-gradient(180deg, #FF896F 0%, #F54A24 100%)",
    image: "/images/dashboard/profile/cocking.png",
    name: "Cooking",
  },
  {
    id: 7,
    bg: "linear-gradient(158deg, #FFCD71 2.05%, #FFA600 85.63%)",
    image: "/images/dashboard/profile/astronomy.png",
    name: "astronomy",
  },
  {
    id: 8,
    bg: "linear-gradient(156deg, #725DF0 5%, #230AB3 84.61%)",
    image: "/images/dashboard/profile/chemistry.png",
    name: "Acting",
  },
  {
    id: 9,
    bg: "linear-gradient(180deg, #37CAAE 0%, #67F8BC 100%)",
    image: "/images/dashboard/profile/bio.png",
    name: "biology",
  },
  {
    id: 10,
    bg: "linear-gradient(180deg, #3CA7A3 0%, #035451 100%)",
    image: "/images/dashboard/profile/sport.png",
    name: "Sports",
  },
  {
    id: 11,
    bg: "linear-gradient(156deg, #D339A4 5%, #FF00B1 84.61%)",
    image: "/images/dashboard/profile/chemistry.png",
    name: "Chemistry",
  },
];
