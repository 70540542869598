import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { IoMdCall, IoMdMail } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { getDistricts, register, studRegister } from "../../axios/axios";
import { FaSchool, FaUser } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Classroom() {
  const [iAgreeTermsConditions, setIAgreeTermsConditions] = useState(false);

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/", {});
  };

  const [districts, setDistricts] = useState([]);

  const [showPasswords, setShowPasswords] = useState({
    password1: false,
    password2: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPass: "",
      district: "",
      districtId: "",
      contactPersonName: "",
      contactPersonPhone: "",
      contactPersonDesignation: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .matches(
          /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          "Invalid Email format"
        )
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPass: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      district: Yup.string().required("District is required"),
      contactPersonName: Yup.string().required(
        "Contact Person Name is required"
      ),
      contactPersonPhone: Yup.string().required(
        "Contact Person Phone is required"
      ),
      contactPersonDesignation: Yup.string().required(
        "Contact Person Designation is required"
      ),
      districtId: Yup.string().required("District is required"),
    }),
    onSubmit: async (values) => {
      if (!iAgreeTermsConditions) {
        toast.error("Please read and accept terms and conditions to proceed!");
        return; // Prevent form submission if checkbox is unchecked
      }
      console.log(values);
      await studRegister({
        name: values.name,
        email: values.email,
        password: values.password,
        district: values.district,
        districtId: values.districtId,
        contactPersonName: values.contactPersonName,
        contactPersonPhone: values.contactPersonPhone,
        contactPersonDesignation: values.contactPersonDesignation,
      })
        .then((response) => {
          navigate("/otp", {
            state: {
              type: "school",
              email: values.email,
              data: { button: "Submit" },
            },
          });
        })
        .catch((err) => {
          console.error("register failed:", err);
          if (typeof err.error == "string") {
            toast.error(err.error);
          } else {
            toast.error("Invalid email or password. Please try again.");
          }
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Full Name is required";
        toast.error(errors.name);
      }
      if (!values.email) {
        errors.email = "Email is required";
        toast.error(errors.email);
      } else if (
        !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(values.email)
      ) {
        errors.email = "Invalid format";
        toast.error(errors.email);
      }
      if (!values.password) {
        errors.password = "Password is required";
        toast.error(errors.password);
      }
      if (values.password.length < 8) {
        errors.password = "Password length should be minimum of 8 characters!";
        toast.error(errors.password);
      }
      if (!values.confirmPass) {
        errors.confirmPass = "Confirm Password is required";
        toast.error(errors.confirmPass);
      } else if (values.confirmPass !== values.password) {
        errors.confirmPass = "Password and confirm password should must match";
        toast.error(errors.confirmPass);
      }
      return errors;
    },
  });

  const fetchDistricts = async () => {
    try {
      const response = await getDistricts();
      setDistricts([
        { value: "", district: "Select District" },
        ...response.data,
      ]);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  // make the school List dynamic

  useEffect(() => {
    fetchDistricts();
  }, []);

  return (
    <div>
      <style>
        {`
          .no-spinners::-webkit-inner-spin-button,
          .no-spinners::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
      </style>
      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { marginTop: 1.5 },
            "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after":
              {
                borderBottom: "none !important",
              },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
            "& .MuiFormLabel-root": {
              padding: "15px 3px 0px 21px",
              fontSize: "17px",
              color: "black",
            },
            "& .MuiInputBase-root": {
              padding: "10px 0px 3px 15px",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="school-name"
            label="School Name"
            placeholder="John High School"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaSchool />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.name && Boolean(formik.errors.name)}
          />

          <FormControl
            fullWidth
            className="w-full bg-white rounded-xl"
            sx={{ marginTop: "10px" }}
          >
            <InputLabel
              variant="standard"
              htmlFor="school-native-select"
              shrink
              sx={{ fontSize: "14px", color: "#636363" }}
              error={formik.touched.district && Boolean(formik.errors.district)}
            >
              School District
            </InputLabel>
            <NativeSelect
              name="district"
              value={formik.values.district}
              onChange={(e) => {
                const selectedDistrict = districts.find(
                  (item) => item.district === e.target.value
                );
                formik.setFieldValue("districtId", selectedDistrict._id);
                formik.setFieldValue("district", e.target.value);
              }}
              inputProps={{
                name: "district",
                id: "school-native-select",
              }}
              sx={{
                color: "#636363",
                fontSize: "14px",
                margin: "0px 10px 0px 1px",
              }}
            >
              {districts?.map((item, index) => {
                return (
                  <option key={index} value={item.district} _id={item._id}>
                    {item.district}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <TextField
            id="email"
            label="Email"
            placeholder="johndoe@gmail.com"
            value={formik.values.email.toLowerCase().trim()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.email && Boolean(formik.errors.email)}
          />

          <TextField
            id="contactPersonName"
            label="Contact Person Name"
            placeholder="John Doe"
            value={formik.values.contactPersonName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="contactPersonName"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaUser />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={
              formik.touched.contactPersonName &&
              Boolean(formik.errors.contactPersonName)
            }
          />
          {/* 
          <TextField
            id="input-with-icon-textfield"
            label="Contact Person Email"
            placeholder="johndoe@gmail.com"
            value={formik.values.contactPersonEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="contactPersonEmail"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={
              formik.touched.contactPersonEmail &&
              Boolean(formik.errors.contactPersonEmail)
            }
          /> */}
          {/* {formik.touched.contactPersonEmail &&
            formik.errors.contactPersonEmail && (
              <FormHelperText sx={{ color: "red", mt: 0.5 }}>
                {formik.errors.contactPersonEmail}
              </FormHelperText>
            )} */}
          <TextField
            id="contactPersonPhone"
            label="Contact Person Phone"
            placeholder="9876543210"
            value={formik.values.contactPersonPhone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="contactPersonPhone"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            InputProps={{
              inputProps: {
                className: "no-spinners",
              },
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdCall />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={
              formik.touched.contactPersonPhone &&
              Boolean(formik.errors.contactPersonPhone)
            }
          />

          <TextField
            id="contactPersonDesignation"
            label="Contact Person Designation"
            placeholder="Principal"
            value={formik.values.contactPersonDesignation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="contactPersonDesignation"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaUser />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="pt-10 w-full bg-white rounded-xl"
            error={
              formik.touched.contactPersonDesignation &&
              Boolean(formik.errors.contactPersonDesignation)
            }
          />

          <TextField
            id="password"
            variant="standard"
            label="Create New Password"
            placeholder="***********"
            type={showPasswords.password1 ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="password"
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password1")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password1 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="pt-10 w-full bg-white rounded-xl"
            error={formik.touched.password && Boolean(formik.errors.password)}
          />

          <TextField
            id="confirmPass"
            variant="standard"
            label="Confirm Password"
            placeholder="***********"
            type={showPasswords.password2 ? "text" : "password"}
            value={formik.values.confirmPass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="confirmPass"
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password2")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password2 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="pt-10 w-full bg-white rounded-xl"
            error={
              formik.touched.confirmPass && Boolean(formik.errors.confirmPass)
            }
          />
        </Box>

        <div className="flex gap-2 justify-center mt-6 max-w-full">
          <div className="flex flex-col justify-center p-0.5">
            <input
              type="checkbox"
              className="w-5 h-5 max-md:w-4 max-md:h-4"
              checked={iAgreeTermsConditions}
              onChange={() => setIAgreeTermsConditions((prev) => !prev)}
            />
          </div>
          <div className="text-base leading-6">
            I agree with
            <span className="pl-1 text-blue-600 cursor-pointer">
              <Link to="/terms-conditions">terms & conditions</Link>
            </span>
          </div>
        </div>
        <button
          type="submit"
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
        >
          Send OTP
        </button>
        <div className="flex justify-center mt-7 text-base text-slate-950">
          Already have an account?
          <button
            onClick={() => goToLogin()}
            className="pl-1 font-medium text-blue-600 cursor-pointer"
          >
            Login
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Classroom;
