import React, { useState, useEffect } from "react";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import { createPost, updatePost } from "../../../axios/axios";
import {
  CallingAxios,
  ClearPopupMemory,
  TrimJsonValues,
} from "../commonFunction";
import { useAuth } from "../../../authContext/AuthContext";

const EditCelebration = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupMemory = useSelector((state) => state.common.PopupMemory);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    text: "",
    images: [],
  });
  const { user } = useAuth();
  useEffect(() => {
    if (popupMemory?.enable && popupMemory?.address === "edit") {
      setIsOpen(true);
      setInitialValues(popupMemory?.initialValue);
    }
  }, [popupMemory]);

  const handleClose = () => {
    setIsOpen(false);
    ClearPopupMemory();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      text: initialValues?.text,
      images: [],
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = TrimJsonValues(values);

      if (data.text !== "" && user.candidateId) {
        CallingAxios(
          await createPost(user.candidateId, data)
            .then((response) => {
              handleClose();
              toast.success("Post created successfully");
            })
            .catch((err) => {
              toast.error(err.message);
            })
        );
      } else {
        await updatePost()
          .then((response) => {})
          .catch((err) => {
            toast.error(err.message);
          });
      }
      setLoading(false);
    },
  });

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImages = [...formik.values.images, file];
      formik.setValues({ ...formik.values, images: newImages });
    }
  };

  const handleDeleteFile = (idx) => {
    const newImages = formik.values.images.filter((_, index) => index !== idx);
    formik.setFieldValue("images", newImages);
  };

  const onChange = (e) => {
    const value = e.target.value;
    if (value.length <= 200) {
      formik.setFieldValue("text", value);
    }
  };

  return (
    isOpen && (
      <>
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto outline-none focus:outline-none">
          <div className="relative w-full mx-5 my-6 mx:w-auto xl:mx-auto xl:max-w-3xl">
            <div className="flex flex-col items-start w-full p-4 bg-white rounded-3xl max-md:p-5 max-md:max-w-full">
              <div className="flex justify-between w-full">
                <div className="my-auto text-xl font-medium text-black">
                  Share your thoughts
                </div>
                <img
                  onClick={handleClose}
                  loading="lazy"
                  alt=""
                  src="/images/dashboard/celebrate/cross.png"
                  className="my-auto bg-gray-50 cursor-pointer rounded-full aspect-square w-[35px]"
                />
              </div>
              <div className="w-full mt-5">
                <Textarea
                  placeholder="What’s on your mind Joe?"
                  value={formik.values.text}
                  onChange={onChange}
                  minRows={2}
                  maxRows={4}
                  endDecorator={
                    <Typography level="body-xs" sx={{ ml: "auto", mr: "10px" }}>
                      {formik.values.text.length} /200
                    </Typography>
                  }
                  sx={{
                    minWidth: 300,
                    borderRadius: "15px",
                    height: "20vh",
                    backgroundColor: "white",
                    boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05) ",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
              <div className="flex flex-wrap justify-between w-full gap-5 mt-5 overflow-x-auto max-md:max-w-full">
                <div className="grid xl:grid-cols-7 grid-cols-4 gap-3.5">
                  {formik.values.images.map((singleImage, idx) => (
                    <div className="relative" key={idx}>
                      <img
                        onClick={() => handleDeleteFile(idx)}
                        loading="lazy"
                        src={"/images/dashboard/celebrate/delete-post.png"}
                        className="absolute top-0 right-0 w-5 cursor-pointer"
                        alt=""
                      />
                      <img
                        loading="lazy"
                        src={URL.createObjectURL(singleImage)}
                        className="w-16 border aspect-square rounded-xl"
                        alt=""
                      />
                    </div>
                  ))}
                  <div>
                    <input
                      type="file"
                      id="file-input"
                      onChange={onFileSelect}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      className="flex justify-between w-full cursor-pointer"
                    >
                      <img
                        loading="lazy"
                        src="/images/dashboard/celebrate/add-post.png"
                        className="w-16 aspect-square"
                        alt=""
                      />
                    </label>
                  </div>
                </div>
              </div>
              <Button
                onClick={formik.handleSubmit}
                loading={loading}
                style={"w-full mt-4"}
                text={"Post"}
              />
            </div>
          </div>
        </div>
        <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
      </>
    )
  );
};

export default EditCelebration;
