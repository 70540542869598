import { Axios } from "./axios.interceptor";

export const getSubscriptionPlan = async (type) => {
  return await Axios.get(`/get-subscription?type=${type}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
