import JSEncrypt from "jsencrypt";
import React, { useRef, useEffect } from "react";
import { useAuth } from "../authContext/AuthContext";
import { IoIosArrowBack } from "react-icons/io";

const IframeBehavioralQuiz = () => {
  const { user } = useAuth();
  const encryption = new JSEncrypt();
  encryption.setPublicKey(process.env.REACT_APP_DNA_KEY);
  const encryptedKey = encryption.encrypt(process.env.REACT_APP_DNA_SUB_KEY);

  const domain = process.env.REACT_APP_DNA_DOMAIN;
  const account_id = process.env.REACT_APP_DNA_ACCOUNTID;
  const self_registration_id = process.env.REACT_APP_DNA_REGISTRATION;
  const firstname = user?.name.split(" ")[0] || "user";
  const lastname = user?.name.split(" ")[1] || "user";
  const email = user?.email;
  const questionPattern = 11;
  const iframeRef = useRef(null);

  useEffect(() => {
    iframeRef.current.onload = () => {
      const messageTarget = process.env.REACT_APP_DNA_DOMAIN;
      iframeRef.current.contentWindow?.postMessage(encryptedKey, messageTarget);
    };
  }, []);

  return (
    <div className="relative">
      <iframe
        ref={iframeRef}
        title="BehavioralQuiz"
        className="w-full h-[80vh] mt-4 no-scrollbar"
        src={`${domain}?accountId=${account_id}&selfRegistrationId=${self_registration_id}&questionPattern=${questionPattern}&firstname=${firstname}&lastname=${lastname}&email=${email}`}
      ></iframe>
      <IoIosArrowBack className="absolute top-0 w-auto h-10 text-[#366FFC] cursor-pointer" />
    </div>
  );
};

export default IframeBehavioralQuiz;
