import React, { useEffect, useState } from "react";
import { CallingAxios, imageLink } from "../../auth/components/commonFunction";
import { AddComment, UseGetPost } from "../../axios/axios";
import { useSelector } from "react-redux";
import { useAuth } from "../../authContext/AuthContext";
import moment from "moment/moment";
import { toast } from "react-toastify";

const CommentSection = ({ data }) => {
  // State to manage the input comment, list of comments, and emoji list visibility
  const [inputComment, setInputComment] = useState("");
  const [comments, setComments] = useState([]);
  const [showEmojis, setShowEmojis] = useState(false);
  const [postData, setPostData] = useState(null);
  const { user } = useAuth();

  // List of emojis
  const emojis = ["😊", "😂", "😍", "😢", "😠"];

  // Handle input change
  const handleInputChange = (e) => {
    setInputComment(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputComment.trim()) {
      const newComment = {
        candidateId: user.candidateId,
        text: inputComment,
      };
      CallingAxios(AddComment(postData._id, newComment))
        .then((response) => {
          setInputComment("");
          toast.success("Comment added successfully");
          setComments([...comments, newComment]);
        })
        .catch((err) => {
          toast.error(err.message || "Some Error on post fetching");
        });
      setInputComment(""); // Clear input field
    }
  };

  // Handle emoji click
  const handleEmojiClick = (emoji) => {
    setInputComment(inputComment + emoji);
    setShowEmojis(false); // Hide emoji list after selection
  };
  const fetchOneLatestPost = async () => {
    await CallingAxios(UseGetPost(1, 1, ""))
      .then((response) => {
        setPostData(response.posts[0]);
        setComments(response.posts[0].comments);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchOneLatestPost();
  }, []);
  return (
    <div className="flex flex-col items-start w-full px-5 py-5 mx-auto bg-white shadow-2xl rounded-3xl max-md:px-3 max-md:mt-6 max-md:max-w-full">
      <div className="flex gap-5 leading-loose text-black max-md:ml-1.5">
        <img
          loading="lazy"
          src={
            postData?.profileInfo?.profilePhoto
              ? imageLink(postData?.profileInfo?.profilePhoto)
              : "/images/dashboard/Dummy_User.jpg"
          }
          alt=""
          className=" rounded-[100px] w-10 h-10"
        />
        <div className="flex flex-col my-auto">
          <div className="text-base font-medium">
            {postData?.profileInfo?.name}
          </div>
          <div className="text-xs max-md:mr-1.5 opacity-50">
            {moment(postData?.createdAt).fromNow()}
          </div>
        </div>
      </div>
      <img
        loading="lazy"
        src={imageLink(postData?.media[0]) || "/images/dashboard/post.png"}
        alt=""
        className="object-contain self-stretch mt-4 w-full aspect-[1.82] max-md:mr-1.5 max-md:max-w-full"
      />
      <div className="mt-2.5 text-sm leading-6 text-neutral-700 max-md:ml-1.5">
        {postData?.content}
      </div>
      <div className="flex gap-5 mt-3 leading-none text-black whitespace-nowrap max-md:ml-1.5">
        <div className="flex gap-1.5 text-sm">
          <img
            loading="lazy"
            src="/images/dashboard/clappingHand.png"
            alt=""
            className="object-contain w-6 shrink-0 aspect-square"
          />
          <div className="my-auto">{postData?.likes?.length}</div>
        </div>
        <div className="flex gap-1.5 text-sm">
          <img
            loading="lazy"
            src="/images/dashboard/comment.png"
            alt=""
            className="object-contain w-6 shrink-0 aspect-square"
          />
          <div className="my-auto">{postData?.comments?.length}</div>
        </div>
      </div>
      <div className="mt-5 text-sm font-medium leading-snug text-blue-600 max-md:ml-1.5">
        Comments
      </div>
      <div
        style={{ scrollbarWidth: "none" }}
        className="max-h-[50vh] w-80 overflow-x-hidden flex-wrap overflow-y-auto"
      >
        {comments.map((item, index) => (
          <div key={index}>
            <div className="flex gap-4 mt-2.5 leading-loose text-black max-md:ml-1.5">
              <img
                loading="lazy"
                src={
                  item?.profilePicture
                    ? imageLink(item?.profilePicture)
                    : "/images/dashboard/Dummy_User.jpg"
                }
                alt=""
                className="object-contain shrink-0 aspect-[1.02] rounded-[100px] w-[52px]"
              />
              <div className="flex flex-col my-auto">
                <div className="text-sm font-medium">{item?.user?.name}</div>
                <div className="self-start text-xs">
                  {moment(item?.createdAt).fromNow()}
                </div>
              </div>
            </div>
            <div className="self-stretch mt-1.5  mr-4 ml-3 text-sm leading-6 text-black max-md:mr-2.5 max-md:max-w-full">
              {item.text}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col w-full gap-4 ">
        <div className="flex self-stretch justify-between w-full min-w-full gap-4 px-2 py-4 mt-5 max-md:px-1 rounded-3xl bg-neutral-100 max-md:max-w-full">
          <form
            onSubmit={handleSubmit}
            className="flex items-center w-full gap-2 text-black max-md:text-sm"
          >
            <button
              type="button"
              onClick={() => setShowEmojis(!showEmojis)}
              className="flex items-center justify-center w-6 aspect-square"
            >
              <img
                loading="lazy"
                src="/images/dashboard/emoji.png"
                alt=""
                className="w-6 aspect-square"
              />
            </button>

            <input
              type="text"
              value={inputComment}
              onChange={handleInputChange}
              placeholder="Type here...."
              className="flex-1 outline-none border-none bg-neutral-100 pl-0.5 py-1 rounded"
            />

            <button
              type="submit"
              className="flex items-center justify-center w-8 aspect-square"
            >
              <img
                loading="lazy"
                src="/images/dashboard/send.png"
                className="w-6 aspect-square"
                alt=""
              />
            </button>
          </form>
        </div>
        {showEmojis && (
          <div className="flex gap-2 mt-2 mb-4">
            {emojis.map((emoji, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handleEmojiClick(emoji)}
                className="text-xl"
              >
                {emoji}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentSection;
