import React, { useEffect, useState } from "react";
import HeaderDash from "./components/HeaderDash";
import MicrocredentialsData from "./components/MicrocredentialsData";
import { IoIosSearch } from "react-icons/io";
import CourceDescription from "./components/CourceDescription";
import CommentSection from "./components/CommentSection";
import SingleMicrocredentials from "../components/Microcredentials";
import { CallingAxios } from "../auth/components/commonFunction";
import { toast } from "react-toastify";
import { UseGetMicroCreadiential } from "../axios/axios";
import { useAuth } from "../authContext/AuthContext";

const Microcredentials = ({ setActiveTab }) => {
  const { user } = useAuth();
  const [allMicro, setAllMicro] = useState([]);
  const [selectedMicro, setSelectedMicro] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [timeOut, setTimeOut] = useState(null);
  useEffect(() => {
    CallGetMicrovredentials();
  }, []);

  const CallGetMicrovredentials = async () => {
    await CallingAxios(
      UseGetMicroCreadiential(user.candidateId, searchQuery)
        .then((response) => {
          setAllMicro(response.data);
        })
        .catch((err) => {
          toast.error("some tecnical error");
        })
    );
  };
  useEffect(() => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(() => {
        CallGetMicrovredentials();
      }, 1000)
    );
  }, [searchQuery]);
  return (
    <main className="p-4 ">
      <HeaderDash setActiveTab={setActiveTab} />
      {selectedMicro ? (
        <CourceDescription
          goBack={() => setSelectedMicro()}
          data={selectedMicro}
        />
      ) : (
        <div className="mt-8 max-md:mr-2.5 max-lg:max-w-full">
          <div className="flex gap-5 max-lg:flex-col">
            <div className="flex flex-col w-[67%] max-md:ml-0 max-lg:w-full">
              <div className="flex flex-col mt-1.5 w-full max-md:mt-8 max-md:max-w-full">
                <div className="flex flex-wrap items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600">
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-right.png"
                    className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
                  />
                  <div className="self-start flex-auto max-md:max-w-full">
                    Dream big, strive for greatness!
                  </div>
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-left.png"
                    className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
                  />
                </div>

                <div className="flex flex-col w-full px-5 mt-3 bg-white shadow-2xl py-7 rounded-xl max-md:px-5 max-md:max-w-full">
                  <div className="flex flex-wrap justify-between max-w-full gap-10">
                    <div className="text-2xl font-bebas tracking-[1px] md:my-auto text-slate-950">
                      MICROCREDENTIALS
                    </div>
                    <div className="flex gap-2  px-3 py-2 text-sm leading-relaxed text-black bg-white border-[#E8E8E8] border-[1px] rounded-xl ">
                      <IoIosSearch className="my-auto text-2xl text-gray-400 " />
                      <input
                        type="search"
                        name="search-form"
                        id="search-form"
                        className="my-auto border-none outline-none "
                        placeholder="Search course"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{ scrollbarWidth: "none" }}
                    className="h-screen mt-5 overflow-y-auto"
                  >
                    {allMicro.map((item, idx) => (
                      <SingleMicrocredentials
                        key={idx}
                        item={item}
                        onClick={() => setSelectedMicro(item)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[33%]  max-md:ml-0  max-lg:w-full">
              <CommentSection />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Microcredentials;
