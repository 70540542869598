import { configureStore } from "@reduxjs/toolkit";
import cPlanReducer from "./c_plans_slice";
import commonReducer from "./common";
import globalValuesReducer from "./global_values";
export const store = configureStore({
  reducer: {
    careerPlanState: cPlanReducer,
    common: commonReducer,
    globalValues: globalValuesReducer,
  },
});
