import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./auth/SignUp";
import ForgotPass from "./auth/ForgotPass";
import Otp from "./common/Otp";
import CreatePass from "./auth/CreatePass";
import TermsCondition from "./auth/TermsCondition";
import Subscriptions from "./subscription/Subscriptions";
import Login from "./auth/Login";
import ProceedPayment from "./subscription/ProceedPayment";
import Congratulation from "./subscription/Congratulation";
import AdminIndex from "./dashboard/AdminIndex";
import Main from "./dashboard-student/Main";
import AuthContext from "./authContext/AuthContext";
import AuthenticLayout from "./wrapper/authenticationLayout/AuthenticLayout";
import NotAuthenticationLayout from "./wrapper/authenticationLayout/NotAuthenticationLayout";
import DeleteAccount from "./delete-account/DeleteAccount";
import AuthenticLayoutSchool from "./wrapper/authenticationLayout/AuthenticLayoutSchool";
function App() {
  return (
    <div>
      <AuthContext>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={
                <NotAuthenticationLayout>
                  <Login />
                </NotAuthenticationLayout>
              }
            />

            <Route
              path="/signUp"
              element={
                <NotAuthenticationLayout>
                  <SignUp />
                </NotAuthenticationLayout>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <NotAuthenticationLayout>
                  <ForgotPass />
                </NotAuthenticationLayout>
              }
            />
            <Route path="/otp" element={<Otp />} />
            <Route path="/create-password" element={<CreatePass />} />
            <Route path="/terms-conditions" element={<TermsCondition />} />

            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/proceed-payment" element={<ProceedPayment />} />
            <Route
              path="/congratulation"
              element={
                <AuthenticLayout>
                  <Congratulation />
                </AuthenticLayout>
              }
            />
            {/* path="/admin-index" */}
            <Route
              path="/"
              element={
                <AuthenticLayout>
                  <AdminIndex />
                </AuthenticLayout>
              }
            />
            <Route
              path="/school-portal"
              element={
                <AuthenticLayoutSchool>
                  <Main />
                </AuthenticLayoutSchool>
              }
            />
            <Route path="/delete-account" element={<DeleteAccount />} />
          </Routes>
        </Router>
      </AuthContext>
    </div>
  );
}

export default App;
