import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    LoaderMemory: {
        enable: false,
    },
    c_plan_answered: false,
    activeTabMemory: 1,
    schoolActiveTabMemory: 1,
    // PopupMemory: {
    //     enable: true,
    //     address: '',
    //     type: 'new',
    //     initialValue: ''
    // }
}
export const c_plans_slice = createSlice({
    name: "cPlanning",
    initialState,
    reducers: {
        c_plan_update(state, action) { state.c_plan_answered = action.payload },
        activeTabAction(state, action) { state.activeTabMemory = action.payload },
        schoolActiveTabAction(state, action) { state.schoolActiveTabMemory = action.payload },
        // PopupAction(state, action) { state.PopupMemory = action.payload },
        LoaderAction(state, action) { state.LoaderMemory = action.payload },
    }
})
export const { c_plan_update, schoolActiveTabAction, activeTabAction, LoaderAction } = c_plans_slice.actions;
export default c_plans_slice.reducer;