import React, { useState, useRef } from "react";
import { FaArrowLeft } from "react-icons/fa";

function OtherDocuments({ onFilesSave, onCancel }) {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToRemove, setFileToRemove] = useState(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const getFileIcon = (type) => {
    if (type.includes("pdf")) return "/images/dashboard/profile/pdf.png";
    if (type.includes("word")) return "/images/dashboard/profile/word.png";
    return "/images/dashboard/profile/default.png";
  };

  const handleRemoveClick = (file) => {
    setFileToRemove(file);
    setIsModalOpen(true);
  };

  const confirmRemoveFile = () => {
    setFiles(files.filter((file) => file !== fileToRemove));
    setIsModalOpen(false);
  };

  const handleSave = () => {
    onFilesSave(files); // Call the parent function with the file data
  };

  return (
    <div className="flex flex-col p-5 mt-6 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
      <div className="flex gap-4 justify-between items-start self-start max-w-full">
        <div
          onClick={onCancel}
          style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
          className="p-3 bg-white rounded-full cursor-pointer max-md:p-2"
        >
          <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
        </div>
        <div className="self-start mt-2 text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
          Add documents
        </div>
      </div>
      <div className="self-start ml-14 text-sm leading-loose text-neutral-400 max-md:ml-2.5">
        In this section, add your multiple documents such as grade cards, resume
        etc, which will be added in the profile.
      </div>
      <div className="px-24 max-md:px-0">
        <div
          onClick={handleUploadClick}
          className="flex flex-col justify-center items-center self-center px-10 py-7 mt-9 w-full max-w-full leading-loose text-center bg-opacity-80 rounded-xl border border-dashed bg-zinc-100 border-stone-300 max-md:px-5"
        >
          <div className="flex flex-col items-center w-full max-w-full">
            <img
              loading="lazy"
              src="/images/dashboard/profile/upload.png"
              className="object-contain aspect-square w-[30px]"
            />
            <div className="self-stretch mt-2 text-xs bg-clip-text bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] text-indigo-950">
              <span className="text-blue-600">Browse </span>
              <span className="text-indigo-950">your files</span>
            </div>
            <div className="text-xs text-neutral-400">Supports JPEG, PNG</div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".pdf, .xml, .doc, .docx, .jpeg, .png, .svg, .jpg"
            multiple
          />
        </div>
        {files.length > 0 && (
          <div className="flex flex-wrap gap-4 mt-4 text-sm text-neutral-700">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex relative flex-col pt-1.5 w-32 rounded-none max-md:w-28"
              >
                <div className="flex flex-col items-center pb-2 h-32 bg-sky-200 bg-opacity-20 rounded-xl max-md:h-28">
                  <div className="flex flex-col justify-center items-center self-stretch h-[65px] max-md:h-[55px] bg-white rounded-xl border-4 border-sky-50 border-solid">
                    <img
                      loading="lazy"
                      src={getFileIcon(file.type)}
                      className="w-[20px] max-md:w-[16px]"
                    />
                  </div>
                  <div className="text-xs leading-snug text-black px-1 pt-1 max-md:text-[11px] ">
                    {file.name}
                  </div>
                  <div className="px-2 text-xs leading-snug text-stone-500">
                    {file.size}
                  </div>
                </div>
                <button
                  onClick={() => handleRemoveClick(file)}
                  className="absolute top-0 right-0 z-10 flex justify-center items-center text-white rounded-full w-5 h-5 bg-[#DD2025]"
                >
                  &#10005;
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="self-center mt-28 w-full max-w-full text-base text-center text-white whitespace-nowrap max-md:gap-3">
          <button
            onClick={handleSave}
            className="p-4 max-w-full w-full text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
          >
            Save
          </button>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen ? (
        <>
          <div className="flex overflow-y-auto overflow-x-hidden fixed inset-0 z-50 justify-center items-center outline-none focus:outline-none max-md:mx-3">
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="flex relative flex-col w-full bg-white rounded-2xl border-0 shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col rounded-none">
                  <div className="flex flex-col pt-7 pb-16 w-full bg-white rounded-3xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-end mr-6 h-8 w-8 bg-white rounded-2xl shadow-[0px_20px_50px_rgba(0,0,0,0.12)] max-md:mr-2.5">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="text-blue-600"
                      >
                        &#10005;
                      </button>
                    </div>
                    <div className="flex flex-col px-10 mt-1 w-full text-center max-md:mt-10 max-md:max-w-full">
                      <div className="text-2xl text-[#FF5271] font-bebas tracking-[0.5px] max-md:max-w-full max-md:text-xl">
                        Delete document
                      </div>
                      <div className="text-base leading-relaxed text-neutral-400 max-md:max-w-full">
                        Are you sure you want to cancel your document?
                      </div>
                      <button
                        onClick={confirmRemoveFile}
                        className="md:w-[80%] mx-auto max-md:w-full px-3 py-4 mt-10 text-sm text-white whitespace-nowrap bg-[#FF1942] rounded-2xl "
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-20"></div>
        </>
      ) : null}
    </div>
  );
}

export default OtherDocuments;
