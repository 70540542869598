import React, { useState } from "react";
import Slick1 from "../common/Slick1";
import Individual from "./components/Individual";
import Classroom from "./components/Classroom";

const SignUp = () => {
  const [active, setActive] = useState("Individual");

  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top bg-cover min-w-[320px] min-h-[100vh]"
    >
      <div className="flex py-5 lg:justify-between max-lg:px-16 max-md:px-5">
        <div className="flex flex-col lg:mx-[10%] lg:w-[30%]  ">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[100px]"
            alt="Logo"
          />
          <div className="self-center mt-2.5 font-bebas text-4xl max-md:text-3xl text-green-600 tracking-[2.55px] ">
            WELCOME!
          </div>
          <div className="flex gap-4 self-center p-1 mt-5 mb-2 text-base text-center whitespace-nowrap bg-white rounded-2xl">
            <button
              onClick={() => setActive("Individual")}
              className={`px-6 py-2.5 rounded-2xl ${
                active === "Individual" ? "bg-[#FF9C1F] text-white" : ""
              }`}
            >
              Individual
            </button>
            <button
              onClick={() => setActive("Classroom")}
              className={`px-6 py-2.5 rounded-2xl ${
                active === "Classroom" ? "bg-[#FF9C1F] text-white" : ""
              }`}
            >
              Classroom
            </button>
          </div>

          {/* ...form individual*/}

          <div className={`${active === "Classroom" ? "hidden" : ""}`}>
            <Individual />
          </div>

          {/* ...form classroom*/}

          <div className={`${active === "Classroom" ? "" : "hidden"}`}>
            <Classroom />
          </div>
        </div>
        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
