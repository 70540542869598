import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import { GetMyPosts } from "../../../axios/axios";
import { imageLink } from "../../../auth/components/commonFunction";

const AddShowcase = ({ setAddShowcase, onSave, user }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showcaseData, setShowcaseData] = useState([]);

  const handleItemClick = (id) => {
    console.log(id);
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSave = () => {
    if (onSave) {
      onSave(selectedItems);
    }
    setAddShowcase(false); // Close the component after saving
  };
  const FetchShowcase = async () => {
    const res = await GetMyPosts(page, limit, searchQuery);
    setShowcaseData(res.posts);
  };

  useEffect(() => {
    FetchShowcase();
  }, []);
  return (
    <div className="flex flex-col px-3 py-5 w-full rounded-3xl max-md:px-2 bg-zinc-50 max-md:max-w-full">
      <div className="flex flex-col items-center p-6 w-full bg-white rounded-xl shadow-2xl max-md:p-3 max-md:max-w-full">
        <div className="flex gap-3 justify-between items-start self-start max-w-full">
          <div
            onClick={() => setAddShowcase(false)}
            style={{ boxShadow: " 0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
            className="p-3 bg-white rounded-full cursor-pointer max-md:p-2"
          >
            <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
          </div>
          <div className="self-start mt-2 text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
            SHOWCASE
          </div>
        </div>
        <div className="md:w-[80%] max-md:w-full mt-10 max-md:mt-5 mx-auto">
          <div className="p-4">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="relative mb-4"
            >
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="px-4 py-3 w-full rounded-xl border border-none outline-none"
              />
              <FaSearch className="absolute right-3 top-1/2 text-gray-500 transform -translate-y-1/2" />
            </div>

            <div
              style={{ scrollbarWidth: "none" }}
              className=" flex flex-col gap-3 mt-10 max-md:mt-5 h-[400px]  overflow-auto p-3"
            >
              {showcaseData.length === 0 ? (
                <li>No items found.</li>
              ) : (
                showcaseData.map((item) => (
                  <div
                    key={item._id}
                    onClick={() => handleItemClick(item._id)}
                    style={{
                      boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.12)",
                    }}
                    className={`relative flex flex-wrap gap-3 p-2 max-w-full text-sm bg-white rounded-2xl w-full max-md:pr-5 ${
                      selectedItems.includes(item._id)
                        ? "border-blue-500 border-[1px]"
                        : ""
                    }`}
                  >
                    <img
                      loading="lazy"
                      src={imageLink(item.media[0])}
                      className="object-cover w-20 rounded-2xl shrink-0 aspect-square"
                    />
                    <div className="flex flex-col self-start mt-2 grow shrink-0 basis-0 w-fit max-md:max-w-full">
                      <div className="tracking-normal text-black max-md:max-w-full">
                        {item.content}
                      </div>
                      <div className="flex gap-1.5 self-start mt-2 font-medium text-purple-600">
                        <img
                          loading="lazy"
                          src={imageLink(user.profilePic)}
                          className="object-contain shrink-0 aspect-square rounded-full overflow-hidden w-[25px]"
                        />
                        <div className="my-auto">{user.name}</div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <button
          onClick={handleSave}
          className="p-4 mt-10 mb-5 max-w-full w-[70%] text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddShowcase;
