import { Axios } from "./axios.interceptor";
export const StoreInterest = ({ interest }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/interest`, {
      interests: interest,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
export const StoreShowcase = ({ showcase }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/showcase`, {
      showcase: showcase,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const StoreAchievement = ({ achievement }) => {
  const formData = new FormData();
  formData.append("title", achievement.title);
  formData.append("description", achievement.description);
  formData.append("achievementImages", achievement.achievementImages);
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/achievement`, formData)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const DeleteAchievement = ({ achievementId }) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/delete-achievement`, {
      data: {
        achievementId: achievementId,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const EditAchievement = ({ achievementId, updatedData }) => {
  const formData = new FormData();
  formData.append("title", updatedData.title);
  formData.append("description", updatedData.description);
  if (updatedData.achievementImages) {
    formData.append("achievementImages", updatedData.achievementImages);
  }
  formData.append("achievementId", achievementId);
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/achievement/${achievementId}`, formData)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
