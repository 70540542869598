import React, { useState, useEffect } from "react";
import StudentDashWrapper from "../wrapper/StudentDashWrapper";
import DashboardStud from "./DashboardStud";
import SubscriptionStud from "./SubscriptionStud";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Main = () => {
  const schoolActiveTab = useSelector((state) => state.careerPlanState.schoolActiveTabMemory);

  const location = useLocation();
  let activeValue = location.state;

  // useEffect(() => {
  //   if (activeValue > 0) {
  //     setActiveTab(activeValue);
  //     activeValue = 0;
  //   }
  // }, []);

  return (
    <StudentDashWrapper >
      {schoolActiveTab === 1 && <DashboardStud />}
      {schoolActiveTab === 2 && <SubscriptionStud />}
      {/* {activeTab === 3 && <MyProfile setActiveTab={setActiveTab} />}
      {activeTab === 4 && <Settings setActiveTab={setActiveTab} />} */}
    </StudentDashWrapper>
  );
};

export default Main;
