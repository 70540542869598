import React, { useState, useEffect } from "react";
import DashboardWrapper from "../wrapper/DashboardWrapper";
import Dashboard from "./Dashboard";
import Survey from "./Survey";
import JourneyMap from "./JourneyMap";
import Celebrate from "./Celebrate";
import Mentors from "./Mentors";
import CareerPlanning from "./CareerPlanning";
import Subscriptions from "./Subscriptions";
import Microcredentials from "./Microcredentials";
import { useDispatch, useSelector } from "react-redux";
import CtePathways from "./components/careerPlannings/CtePathways";
import MilitaryOption from "./components/careerPlannings/MilitaryOption";
import Entrepreneurship from "./components/careerPlannings/Entrepreneurship";
import ClgOption from "./components/careerPlannings/ClgOption";
import MyProfile from "./profile-components/MyProfile";
import { useLocation } from "react-router-dom";
import Settings from "./profile-components/Settings";
import { activeTabAction } from "../redux/c_plans_slice";

const AdminIndex = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state) => state.careerPlanState.activeTabMemory
  );
  // const activeTab = 1;
  const location = useLocation();
  let activeValue = location.state;
  const cPlan = useSelector((state) => state.careerPlanState.c_plan_answered);

  const handleNavigateToSurvey = () => {
    dispatch(activeTabAction(3)); // Switch to the Survey tab
  };

  useEffect(() => {
    if (activeValue > 0) {
      dispatch(activeTabAction(activeValue));
      activeValue = 0;
    }
  }, []);

  return (
    <DashboardWrapper>
      {activeTab === 1 && <Dashboard />}
      {activeTab === 2 && <Survey />}
      {activeTab === 3 && <JourneyMap />}
      {activeTab === 4 && <Microcredentials />}
      {activeTab === 5 && <Celebrate />}
      {activeTab === 6 && <Mentors />}
      {activeTab === 7 && !cPlan && (
        <CareerPlanning onNavigateToSurvey={handleNavigateToSurvey} />
      )}
      {activeTab === 8 && <Subscriptions />}

      {activeTab === 10 && <CtePathways />}
      {activeTab === 11 && <MilitaryOption />}
      {activeTab === 12 && <Entrepreneurship />}
      {activeTab === 13 && <ClgOption />}

      {activeTab === 14 && <MyProfile />}
      {activeTab === 15 && <Settings />}
    </DashboardWrapper>
  );
};

export default AdminIndex;
