import React from "react";
import LogoutModal from "./auth/components/allModals/LogoutModal";
import CreateCelebration from "./auth/components/allModals/CreateCelebration";
import DeleteModal from "./auth/components/allModals/DeleteModal";
import EditCelebration from "./auth/components/allModals/EditCelebration";
const AllModals = ({ children }) => {
  return (
    <div>
      {/* <LogoutModal /> */}
      <DeleteModal />
      <CreateCelebration />
      <EditCelebration />
      {children}
    </div>
  );
};

export default AllModals;
