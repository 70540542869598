import React, { useEffect, useState } from "react";
import { FaUser, FaSchool } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getSubscriptionPlan } from "../axios/subscription-api";

const Subscriptions = () => {
  const [selectedPlan, setSelectedPlan] = useState({
    type: "",
    id: "",
    price: 0,
    name: "",
    interval: "",
  });
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const location = useLocation();
  const { subscription } = location.state || {};
  console.log("subscription", subscription);
  const navigate = useNavigate();

  // Set the default plan based on subscription state
  useEffect(() => {
    if (subscription === "school") {
      setSelectedPlan({
        type: "school",
        id: "",
      });
    } else if (subscription === "individual") {
      setSelectedPlan({
        type: "school",
        id: "",
      });
    }
  }, [subscription]);

  const handleProceedToPay = () => {
    if (!selectedPaymentOption) {
      alert("Please select a payment option.");
      return;
    }

    navigate("/proceed-payment", {
      state: {
        selectedPlan: selectedPlan,
        selectedPaymentOption,
        subscription: !subscription ? selectedPlan.type : subscription,
      },
    });
  };

  const fetchSubscriptionPlan = async () => {
    try {
      const response = await getSubscriptionPlan(subscription);
      setSubscriptionPlan(response.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  return (
    <div
      style={{
        backgroundImage: "url(/images/subscription/subscription-bg.png)",
      }}
      className="relative min-w-[320px] min-h-[100vh] bg-fixed bg-top bg-cover bg-no-repeat"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-[#1D68FF] to-[#9A52DE97] opacity-75"></div>
      <div className="flex relative flex-col justify-center items-center px-4 py-8 min-h-full sm:py-16">
        <div className="flex flex-col justify-between items-end md:flex-row">
          <div className="py-5 text-white">
            <h1 className="text-5xl font-bebas max-md:text-4xl tracking-[2.55px]">
              CHOOSE YOUR PLAN
            </h1>
            <p className="mt-1 text-xs md:text-sm">
              Lorem ipsum dolor sit amet consectetur. Tellus ac sociis lobortis
              at fermentum.
            </p>
          </div>
          <img
            src="/images/subscription/student.png"
            alt="not found"
            className="w-3/4 md:w-1/3"
          />
        </div>
        <div className="flex overflow-hidden z-10 flex-col py-10 w-full max-w-5xl bg-white rounded-lg shadow-lg md:flex-row">
          <div className="w-full md:w-[37%] px-10 my-auto pt-5">
            <div className="h-full">
              {subscriptionPlan.map((elem, ind) => (
                <div
                  key={elem._id}
                  style={{ boxShadow: "0px 0px 31px 0px rgba(0, 0, 0, 0.07)" }}
                  className={`p-4 border-2 flex justify-between rounded-3xl mb-4 cursor-pointer ${
                    selectedPlan.type === elem.type &&
                    selectedPlan.id === elem._id
                      ? "border-blue-500"
                      : ""
                  } ${subscription === "school" ? "hidden" : ""}`}
                  onClick={() => {
                    setSelectedPlan({
                      type: elem.type,
                      id: elem._id,
                      price: elem.price,
                      name: elem.name,
                      interval: elem.interval,
                    });
                    setSelectedPaymentOption("");
                  }}
                >
                  <div className="flex flex-col justify-between text-[#919191]">
                    <h2 className="text-lg md:text-xl font-bold text-[#009A39]">
                      ${Math.round(elem.price)}
                    </h2>
                    <p className="text-[#919191]">
                      {elem.interval === "month" ? "Monthly" : "Yearly"}
                    </p>
                    <p className="font-bold text-[#090D20] mt-2">{elem.type}</p>
                    <p className="text-sm">{elem.name}</p>
                  </div>
                  {elem.type === "individual" ? (
                    <FaUser className="text-[#1D68FF] bg-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
                  ) : (
                    <FaSchool className="text-[#1D68FF] bg-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-[63%] bg-white px-10 max-md:mt-10 border-l-[1px]">
            {selectedPlan.id != "" ? (
              <>
                <h2 className="text-2xl max-md:text-xl font-bebas tracking-[2px] text-green-600">
                  TOTAL AMOUNT
                </h2>
                <p className="mt-2 text-gray-700 text-[15px] md:w-[70%]">
                  {selectedPlan.type === "individual"
                    ? `As you have chosen Individual plan, the total amount for subscription is $${Math.round(
                        selectedPlan.price
                      )}`
                    : `As You have chosen Schools plan, the total amount for subscription is $${Math.round(
                        selectedPlan.price
                      )}`}
                </p>
                <h3 className="text-2xl max-md:text-xl font-bebas tracking-[2px] text-green-600 mt-8">
                  CHOOSE PAYMENT OPTION
                </h3>
                <div className="mt-4">
                  {paymentOptions.map((option) => (
                    <div
                      key={option.name}
                      style={{
                        boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)",
                      }}
                      className={`flex items-center justify-between p-4 mb-3 rounded-xl cursor-pointer ${
                        selectedPaymentOption === option.name
                          ? "border-2 border-blue-500"
                          : ""
                      }`}
                      onClick={() => setSelectedPaymentOption(option.name)}
                    >
                      <div className="flex items-center">
                        <img
                          src={option.icon}
                          alt="not found"
                          className="my-auto"
                        />
                        <span className="pl-2 my-auto text-gray-700">
                          {option.name}
                        </span>
                      </div>
                      <span className="text-[#FF9C1F] text-xs">
                        <FaGreaterThan />
                      </span>
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleProceedToPay}
                  className="px-4 py-2 mt-4 w-full font-bold text-white bg-blue-500 rounded-md"
                >
                  Proceed to Pay
                </button>
              </>
            ) : (
              <h2 className="text-3xl max-md:text-xl text-center mt-8 font-bebas tracking-[2px] text-green-600 ">
                Please select a plan
              </h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;

const paymentOptions = [
  {
    name: "Debit Card",
    icon: "/images/subscription/debit.png",
  },
  {
    name: "Credit Card",
    icon: "/images/subscription/credit.png",
  },
  // { name: "Gpay", icon: "/images/subscription/g-pay.png" },
  // {
  //   name: "Paypal",
  //   icon: "/images/subscription/paypal.png",
  // },
];
