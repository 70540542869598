import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ShowExamModel: {
    enable: true,
  },
};
export const global_values_slice = createSlice({
  name: "global_values",
  initialState,
  reducers: {
    ShowExamModelAction(state, action) {
      state.ShowExamModel = action.payload;
    },
  },
});
export const { ShowExamModelAction } = global_values_slice.actions;
export default global_values_slice.reducer;
