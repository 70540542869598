import React, { useState, useRef, useEffect } from "react";
import HeaderDash from "./components/HeaderDash";
import { IoIosSearch } from "react-icons/io";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import Filter from "./components/Filter";
import {
  CallingAxios,
  DeleteConformations,
  HandleModal,
  imageLink,
  EditPopupMemory,
} from "../auth/components/commonFunction";
import {
  AddComment,
  GetMyPosts,
  UseGetComments,
  UseGetPost,
} from "../axios/axios";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useAuth } from "../authContext/AuthContext";

function Celebrate({ setActiveTab }) {
  const { user } = useAuth();
  const [postType, setPostType] = useState("all"); // all and user
  const [page, setPage] = useState(1);
  const [showModalDeletePost, setShowModalDeletePost] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const postRef = useRef();
  const divRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedPost, setSelectedPost] = useState("");
  const [allPosts, setAllPosts] = useState([]);
  const [inputComment, setInputComment] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [comments, setComments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const emojis = ["😊", "😂", "😍", "😢", "😠"];
  const [mytimeOut, setMytimeOut] = useState(null);

  const handleInputChange = (e) => {
    setInputComment(e.target.value);
  };
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputComment.trim()) {
      const newComment = {
        candidateId: user.candidateId,
        text: inputComment,
      };
      CallingAxios(AddComment(selectedPost._id, newComment))
        .then((response) => {
          setInputComment("");
          toast.success("Comment added successfully");
        })
        .catch((err) => {
          toast.error(err.message || "Some Error on post fetching");
        });
      setInputComment(""); // Clear input field
    }
  };
  const TooglePostType = () => {
    handleClear();
    setSearchQuery("");
    setPostType(postType === "user" ? "all" : "user");
  };

  // Handle emoji click
  const handleEmojiClick = (emoji) => {
    setInputComment(inputComment + emoji);
    setShowEmojis(false); // Hide emoji list after selection
  };

  const handleClear = () => {
    setAllPosts([]);
    setPage(1);
    setComments([]);
    setSelectedPost("");
    setActiveId(null);
    setShowModalDeletePost(false);
    setShowOptions(false);
    setShowEmojis(false);
    setInputComment("");
  };

  const CallGetPosts = async () => {
    await CallingAxios(UseGetPost(page, 10, searchQuery, user?.schoolRef))
      .then((response) => {
        if (response && response.status == 200) {
          // console.log(response.posts);
          setAllPosts((prevPosts) => [...prevPosts, ...response.posts]);
        }
      })
      .catch((err) => {
        toast.error(err.message || "Some Error on post fetching");
      });
  };

  const CalGetMyPosts = async () => {
    await CallingAxios(GetMyPosts(page, 10, searchQuery))
      .then((response) => {
        if (response && response.status == 200) {
          setAllPosts((prevPosts) => [...prevPosts, ...response.posts]);
        }
      })
      .catch((err) => {
        toast.error(err.message || "Some Error on post fetching");
      });
  };

  const GetComments = async (postId) => {
    await CallingAxios(UseGetComments(postId))
      .then((response) => {
        console.log(response.data);
        let res = response.data;
        let x = { ...comments };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setComments(x);
      })
      .catch((err) => {
        toast.error(err.message || "Some Error on post fetching");
      });
  };

  useEffect(() => {
    if (postType === "user") {
      CalGetMyPosts();
    } else {
      CallGetPosts();
    }
  }, [page, postType]);

  useEffect(() => {
    if (selectedPost) {
      GetComments(selectedPost._id);
    }
  }, [selectedPost]);

  useEffect(() => {
    clearTimeout(mytimeOut);
    const timeout = setTimeout(() => {
      handleClear();
      if (postType === "user") {
        CalGetMyPosts();
      } else {
        CallGetPosts();
      }
    }, 500);
    setMytimeOut(timeout);
  }, [searchQuery]);
  return (
    <div className="flex flex-col p-4 w-full rounded-3xl bg-zinc-50 max-md:px-5 max-md:max-w-full">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="flex flex-wrap gap-5 justify-between mt-7 w-full h-fit max-md:flex-col max-md:max-w-full">
        <div className="flex justify-between h-fit  pl-4 pr-1 py-1 md:w-[40%] text-sm text-black bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] max-md:max-w-full">
          <div className="flex gap-2 w-[80vw] ">
            <IoIosSearch className="my-auto text-2xl text-gray-400" />
            <input
              type="search"
              name="search-form"
              id="search-form"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="my-auto w-full border-none outline-none"
              placeholder="Search feed "
            />
          </div>
          <img
            onClick={() => HandleModal(true, "new", "post")}
            loading="lazy"
            src="/images/dashboard/celebrate/filter.png"
            className="w-[45px] h-[45px] my-auto  cursor-pointer"
          />
        </div>
        <div
          style={{ scrollbarWidth: "none" }}
          className={`fixed top-0 right-0 h-full z-10 overflow-auto shadow-2xl  transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <Filter setIsOpen={setIsOpen} />
        </div>
        <div className="flex flex-wrap gap-5 text-base text-center max-md:mt-5 max-md:max-w-full">
          <button
            onClick={TooglePostType}
            className={`flex flex-auto text-[#1D68FF] justify-center items-center my-auto px-10 py-3 rounded-xl border border-blue-600 border-solid`}
          >
            {postType === "user" ? "View All Posts" : "View My Posts"}
          </button>
          <button
            onClick={() => HandleModal(true, "new", "post")}
            style={{
              background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
            }}
            className="flex flex-auto gap-2.5 justify-center items-center px-7 py-3 text-white rounded-xl max-md:px-5"
          >
            <div className="text-2xl">+</div>
            <div className="my-auto">Create New Post</div>
          </button>
        </div>
      </div>
      <div className="mt-7 max-md:max-w-full">
        <div className="flex gap-5 max-lg:flex-col">
          {/* <div className="flex flex-col w-[34%] max-md:ml-0 max-lg:w-full">
            <CommentSection data={allPosts[0]} />
          </div> */}
          <div className="flex flex-col max-md:ml-0 max-lg:w-full">
            <div className="flex flex-col w-full max-md:mt-5 max-md:max-w-full">
              <div className="max-md:mr-0.5 max-md:max-w-full">
                <div className="grid grid-cols-3 gap-4 max-md:grid-cols-1">
                  {allPosts.length > 0 ? (
                    allPosts.map((item, index) => {
                      return (
                        <div
                          ref={postRef}
                          key={`${Math.random()}_${index}_Post`}
                          className={`${
                            selectedPost?._id == item?._id &&
                            "col-span-1 row-span-2"
                          } flex flex-col  max-md:w-full`}
                        >
                          <div className="flex flex-col items-start p-3.5 mx-auto w-full text-black bg-white rounded-3xl shadow-2xl max-md:max-w-full">
                            <div className="flex relative justify-between items-start w-full">
                              <div className="flex gap-3">
                                <img
                                  loading="lazy"
                                  src={
                                    item?.profileInfo?.profilePhoto
                                      ? imageLink(
                                          item?.profileInfo?.profilePhoto
                                        )
                                      : "/images/Dummy_User.jpg"
                                  }
                                  className="w-10 h-10 rounded-full"
                                />
                                <div className="flex flex-col my-auto">
                                  <div className="self-start text-[15px] font-medium">
                                    {item?.profileInfo?.name}
                                  </div>
                                  <div className="text-xs">
                                    {moment(item?.createdAt).fromNow()}
                                  </div>
                                </div>
                              </div>
                              {item._id == activeId && (
                                <div className="absolute right-0 top-10">
                                  <div className="flex flex-col text-sm">
                                    <div className="flex flex-col items-start px-4 py-2.5 w-full bg-white rounded-2xl border border-solid border-neutral-200">
                                      <button
                                        id="noCloseButton"
                                        onClick={() => EditPopupMemory(item)}
                                        className="flex gap-2.5 cursor-pointer text-neutral-700"
                                      >
                                        <GoPencil className="text-lg" />
                                        Edit Post
                                      </button>
                                      <button
                                        onClick={() =>
                                          DeleteConformations(
                                            "post",
                                            item._id,
                                            "Delete Post",
                                            "Are you sure you want to delete this post?",
                                            "Delete"
                                          )
                                        }
                                        className="flex gap-2.5 mt-2 text-rose-600 cursor-pointer"
                                        id="noCloseButton"
                                      >
                                        <RiDeleteBinLine className="text-lg" />{" "}
                                        Delete Post
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {postType === "user" && (
                                <div
                                  ref={divRef}
                                  className="text-3xl cursor-pointer"
                                  onClick={() => setActiveId(item?._id)}
                                >
                                  ...
                                </div>
                              )}
                            </div>
                            <img
                              loading="lazy"
                              src={imageLink(item.media[0])}
                              className="object-contain rounded-2xl self-stretch mt-4 w-full aspect-[1.82] max-md:max-w-full"
                            />
                            <div className="mt-2.5 text-sm leading-6 text-neutral-700">
                              {item.content}
                            </div>
                            <div className="flex gap-5 mt-3 leading-none whitespace-nowrap">
                              <div className="flex gap-1.5 text-sm">
                                <img
                                  loading="lazy"
                                  src={"/images/dashboard/celebrate/hand.png"}
                                  className="object-contain shrink-0 aspect-square -translate-y-[2px] w-[26px]"
                                />
                                <div className="my-auto">{item.handCount}</div>
                              </div>
                              <div
                                onClick={() => {
                                  if (selectedPost._id === item._id) {
                                    setSelectedPost("");
                                  } else {
                                    setSelectedPost(item);
                                  }
                                }}
                                className="flex gap-1.5 self-start text-base cursor-pointer"
                              >
                                <img
                                  loading="lazy"
                                  src={
                                    "/images/dashboard/celebrate/comment.png"
                                  }
                                  className="object-contain w-6 shrink-0 aspect-square"
                                />
                                <div>{item?.chatCount}</div>
                              </div>
                            </div>
                            {selectedPost?._id == item?._id && (
                              <div className="w-full">
                                <div className="mt-5 text-sm font-medium leading-snug text-blue-600 max-md:ml-1.5">
                                  Comments
                                </div>
                                <div
                                  style={{ scrollbarWidth: "none" }}
                                  className="overflow-y-auto overflow-x-hidden flex-wrap max-h-44 lg:max-h-64"
                                >
                                  {comments.history &&
                                    comments.history.map((item, index) => (
                                      <div
                                        key={`${Math.random()}_${index}_Comments`}
                                        className="mb-4"
                                      >
                                        {" "}
                                        {/* Margin for separation */}
                                        <div className="flex gap-4 mt-2.5 leading-loose text-black max-md:ml-1.5">
                                          <img
                                            loading="lazy"
                                            src={
                                              item?.user?.profilePicture
                                                ? imageLink(
                                                    item?.user?.profilePicture
                                                  )
                                                : "/images/dashboard/celebrate/profilePic.png"
                                            }
                                            alt="Commenter"
                                            className="object-contain shrink-0 aspect-[1.02] rounded-full w-10 h-10"
                                          />
                                          <div className="flex flex-col my-auto">
                                            <div className="text-sm font-medium">
                                              {item?.user?.name || "User Name"}
                                            </div>
                                            <div className="self-start text-xs">
                                              {moment(item.createdAt).fromNow()}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="self-stretch mt-1.5 mr-4 ml-3 text-sm leading-6 text-black max-md:mr-2.5 max-md:max-w-full">
                                          {item.text}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                <div className="flex flex-col gap-4 mt-4 w-full">
                                  <div className="flex justify-between self-stretch px-2 py-4 rounded-3xl max-md:px-1 bg-neutral-100 max-md:max-w-full">
                                    <form
                                      onSubmit={handleSubmit}
                                      className="flex gap-2 items-center w-full text-black max-md:text-sm"
                                    >
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setShowEmojis(!showEmojis)
                                        }
                                        className="flex justify-center items-center w-6 aspect-square"
                                      >
                                        <img
                                          loading="lazy"
                                          src="/images/dashboard/emoji.png"
                                          className="w-6 aspect-square"
                                        />
                                      </button>

                                      <input
                                        type="text"
                                        value={inputComment}
                                        onChange={handleInputChange}
                                        placeholder="Type here...."
                                        className="flex-1 py-1 pl-0.5 rounded border-none outline-none bg-neutral-100"
                                      />

                                      <button
                                        type="submit"
                                        className="flex justify-center items-center w-8 aspect-square"
                                      >
                                        <img
                                          loading="lazy"
                                          src="/images/dashboard/send.png"
                                          className="w-6 aspect-square"
                                        />
                                      </button>
                                    </form>
                                  </div>
                                </div>
                                {showEmojis && (
                                  <div className="flex gap-2 mt-2 mb-4">
                                    {emojis.map((emoji, index) => (
                                      <button
                                        key={`${Math.random()}_${index}_Emojis`}
                                        type="button"
                                        onClick={() => handleEmojiClick(emoji)}
                                        className="text-xl"
                                      >
                                        {emoji}
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="grid place-items-center w-full h-full">
                      <p className="px-3 py-1 text-2xl rounded-xl border">
                        No posts found
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {allPosts.length >= 10 && (
        <div className="flex justify-center items-center mt-3">
          <p
            onClick={() => setPage(page + 1)}
            className="px-3 py-1 rounded-xl border cursor-pointer hover:border-blue-600 hover:text-blue-600"
          >
            Load More...
          </p>
        </div>
      )}
    </div>
  );
}
export default Celebrate;
