import React from "react";
import HeaderDash from "./components/HeaderDash";
import { useDispatch, useSelector } from "react-redux";
import { activeTabAction } from "../redux/c_plans_slice";
function JourneyMap({ setActiveTab }) {
  const user = useSelector((state) => state.common.userMemory);
  const dispatch = useDispatch();

  const TargetsArray = [
    {
      id: 1,
      lablel: "Interest Survey",
      img: "/images/splash_interest_survey.png",
      btnlabel: "Start Survey",
      link: "InterestSurvey",
    },
    {
      id: 2,
      lablel: "Skill Profiler",
      img: "/images/splash_skill_assess.png",
      btnlabel: "Take Assessment",
      link: "SkillProfiler",
    },
    {
      id: 3,
      lablel: "Career Exploration",
      img: "/images/splash_career_explore.png",
      btnlabel: "Explore Careers",
      link: "CareerExploration",
    },
  ];
  const apiKey = "ev8I2qEdcQKS9T4lQYypAnz6iiYPOxu4lq7bjIlQcbM%3d";
  const handleTarget = (target) => {
    const baseUrl = `https://app.journeysmap.com/API/Session/PrivateAnonymous?APIKey=${apiKey}&Target=${target}`;
    window.open(baseUrl, "_blank");
  };
  return (
    <main className="p-4">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="mt-10 max-md:max-w-full">
        <div className="flex justify-around items-center">
          {user.subscription_status === "active" ? (
            TargetsArray.map((item) => (
              <div
                className="flex flex-col h-72 w-64 justify-center items-center bg-[#F2F3FE] rounded-xl p-5"
                key={item.id}
                onClick={() => {
                  handleTarget(item.link);
                }}
              >
                <div className="flex flex-col justify-center items-center">
                  <img src={item.img} alt={item.lablel} />
                  <p className="text-lg font-medium text-center">
                    {item.lablel}
                  </p>
                </div>
                <p className="mt-5 cursor-pointer text-sm font-medium text-center bg-[#26A08A] text-white rounded-xl p-2">
                  {item.btnlabel}
                </p>
              </div>
            ))
          ) : (
            <div
              className="flex justify-center items-center w-full h-full bg-white rounded-xl shadow-2xl transition-all duration-300 cursor-pointer hover:shadow-none"
              onClick={() => dispatch(activeTabAction(8))}
            >
              <p className="p-4 text-xl">
                Buy Subscription to access this feature
              </p>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
export default JourneyMap;
